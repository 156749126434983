.cloud-solutions.overview{
    padding-top: 6rem;
    padding-bottom: 96px;
    background-color: #fff;
    background-image: url(../../../../../../public/supply-chain/overview-bg.png);
    background-size: 40%;
    background-position: bottom right;
    background-repeat: no-repeat;
    h4.subtitle{
        color: #1D68F0;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
        font-weight: 600;
    }
    h2{
        margin-bottom: 1.5rem;
    }
    .content h4{
        max-width: 680px;
    }
}

// Extra small
@media only screen and (max-width: 600px) {
    .cloud-solutions.overview{
        background-image: none !important;
        padding-bottom: 92px;
        h4{
            max-width: 80%;
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .cloud-solutions.overview{
        background-image: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .cloud-solutions.overview{
        // background-image: url(../../../../../../public/supply-chain/overview-bg.png);
        background-image: initial;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .cloud-solutions.overview{
        // background-image: url(../../../../../../public/supply-chain/overview-bg.png);
        background-image: initial;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .cloud-solutions.overview{
        // background-image: url(../../../../../../public/supply-chain/overview-bg.png);
        background-image: initial;
    }
}