.cloud-solutions.fluid-banner.streamline-banner {
    background-color: #064BD3;
    background-image: url(../../../../../../public/supply-chain/light-blue-banner-bg.png);
    background-position: right center;
    background-repeat: repeat-y;
    background-size: 100%;

    .banner {
        // margin-top: 4rem;
        padding: 72px 0px 72px 0px;
        border-radius: 12px;
        color: white;
        background: none;

        .left {
            width: 100%;
            height: 100%;
        }

        .right {
            width: 100%;
            height: 100%;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: end;

            button:hover {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
}

// .mir-h4-semibold {
//     width: 60%;
// }

// .mir-paragraph-rg-l4-l7 {
//     width: 60%;
// }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .mir-h4-semibold {
        width: 100% !important;
    }
    
    .mir-paragraph-rg-l4-l7 {
        width: 100% !important;
    }

    .cloud-solutions.fluid-banner.streamline-banner {
        .banner {
            .right {
                margin-top: 1rem;
                justify-content: start;
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .cloud-solutions.fluid-banner.streamline-banner {
        .banner {
            .right {
                margin-top: 1rem;
                justify-content: start;
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .cloud-solutions.fluid-banner.streamline-banner {
        .banner {
            .right {
                margin-top: 1rem;
                justify-content: start;
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .cloud-solutions.fluid-banner.streamline-banner {
        .banner {
            .right {
                margin-top: 0rem;
                justify-content: end;
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .cloud-solutions.fluid-banner.streamline-banner {
        .banner {
            .right {
                margin-top: 0rem;
                justify-content: end;
            }
        }
    }
}