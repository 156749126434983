@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities{
    /* paragraph */
    /* semi bold styles */
    .mir-p-lg-semibold{
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 30px */
    }
    .mir-p-md-semibold{
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 170%; /* 30.6px */
    }
    .mir-p-rg-semibold{
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 170%; /* 27.2px */
    }
    .mir-p-sm-semibold{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 170%; /* 23.8px */
    }
    .mir-p-xs-semibold{
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 19.2px */
    }
    /* medium styles */
    .mir-p-lg-medium{
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 30px */
    }
    .mir-p-md-medium{
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 170%; /* 30.6px */
    }
    .mir-p-rg-medium{
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 170%; /* 27.2px */
    }
    .mir-p-sm-medium{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 170%; /* 23.8px */
    }
    .mir-p-xs-medium{
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 19.2px */
    }
    /* regular styles */
    .mir-p-lg-regular{
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
    }
    .mir-p-md-regular{
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%; /* 30.6px */
    }
    .mir-p-rg-regular{
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%; /* 27.2px */
    }
    .mir-p-sm-regular{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%; /* 23.8px */
    }
    .mir-p-xs-regular{
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
    .mir-underline{
        text-decoration: underline;
    }
    .mir-line-through{
        text-decoration: line-through;
    }
    .mir-italic{
        font-style: italic;
    }
    .mir-p-overline-lg{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21px */
        letter-spacing: 0.7px;
        text-transform: uppercase;
    }
    .mir-p-overline-sm{
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 18px */
        letter-spacing: 0.6px;
        text-transform: uppercase;
    }
    /* headings */
    .mir-display-bold-lg {
        font-family: Inter;
        font-size: 56px;
        font-weight: 700;
        line-height: 62px;
        letter-spacing: -0.02em;
        text-align: left;
    }

    .mir-display-semibold-lg {
        font-family: Inter;
        font-size: 56px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        /* 61.6px */
        letter-spacing: -1.12px;
    }

    .mir-display-medium-lg {
        font-family: Inter;
        font-size: 56px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        /* 61.6px */
        letter-spacing: -1.12px;
    }

    .mir-display-bold-sm {
        font-family: Inter;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 57.6px */
        letter-spacing: -0.96px;
    }

    .mir-display-semibold-sm {
        font-family: Inter;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 57.6px */
        letter-spacing: -0.96px;
    }

    .mir-display-medium-sm {
        font-family: Inter;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 57.6px */
        letter-spacing: -0.96px;
    }

    .mir-h1-bold {
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 48px */
        letter-spacing: -0.8px;
    }

    .mir-h1-semibold {
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 48px */
        letter-spacing: -0.8px;
    }

    .mir-h1-medium {
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 48px */
        letter-spacing: -0.8px;
    }

    .mir-h2-bold {
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 43.2px */
        letter-spacing: -0.72px;
    }

    .mir-h2-semibold {
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 43.2px */
        letter-spacing: -0.72px;
    }

    .mir-h2-medium {
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 43.2px */
        letter-spacing: -0.72px;
    }

    .mir-h3-bold {
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        /* 41.6px */
        letter-spacing: -0.64px;
    }

    .mir-h3-semibold {
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        /* 41.6px */
        letter-spacing: -0.64px;
    }

    .mir-h3-medium {
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        /* 41.6px */
        letter-spacing: -0.64px;
    }

    .mir-h4-bold {
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        /* 36.4px */
        letter-spacing: -0.56px;
    }

    .mir-h4-semibold {
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        /* 36.4px */
        letter-spacing: -0.56px;
    }

    .mir-h4-medium {
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        /* 36.4px */
        letter-spacing: -0.56px;
    }

    .mir-h5-bold {
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        /* 33.6px */
        letter-spacing: -0.48px;
    }

    .mir-h5-semibold {
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 33.6px */
        letter-spacing: -0.48px;
    }

    .mir-h5-medium {
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 33.6px */
        letter-spacing: -0.48px;
    }

    .mir-h6-bold {
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.2px;
    }

    .mir-h6-semibold {
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 28px */
        letter-spacing: -0.2px;
    }

    .mir-h6-medium {
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 28px */
        letter-spacing: -0.2px;
    }


    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        .mir-h1-semibold{
            font-family: Inter;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%; /* 36.4px */
            letter-spacing: -0.56px;
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        .mir-h1-semibold{
            font-family: Inter;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%; /* 36.4px */
            letter-spacing: -0.56px;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        .mir-h1-semibold {
            font-family: Inter;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 48px */
            letter-spacing: -0.8px;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        .mir-h1-semibold {
            font-family: Inter;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 48px */
            letter-spacing: -0.8px;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        .mir-h1-semibold {
            font-family: Inter;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 48px */
            letter-spacing: -0.8px;
        }
    }
}