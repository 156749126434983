.cloud-solutions{
    .features{
        padding-top: 5rem;
        padding-bottom: 0;
        .feature-item{
            img{
                margin-bottom: 1.3rem;
            }
            p{
                color: #4B5563;
                max-width: 350px;
            }
        }
    }
}