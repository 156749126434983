.mobile-nav-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 1000000;
    border-top: 1px solid #ccc;
    overflow-y: auto;
    .header-wrapper{
        padding-left: 0.5rem;
    }
    .links-wrapper{
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 85px);
        padding: 0 1rem;
        .links{
            .link{
                padding-bottom: 1.5rem;
                padding-top: 1.5rem;
                border-bottom: 1px solid #EEEEF2;
                display: flex;
                justify-content: space-between;
                align-items: center;
                a{
                    color: #111827;
                }
                img{
                    width: 20px;
                    height: 20px;
                }
            }
            .link:last-child{
                border-bottom: none;
            }
        }
        .contact-btn{
            width: 100%;
            a{
                text-align: center;
                display: flex;
                justify-content: center;
            }
        }
    }
}

.sub-links-wrapper{
    // margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 92%;
    .links{
        padding: 0 0.5rem;
        .link{
            padding-bottom: 1.5rem;
            padding-top: 1.5rem;
            border-bottom: 1px solid #EEEEF2;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a{
                color: #111827;
            }
            img{
                width: 20px;
                height: 20px;
            }
            p{
                padding-right: 1rem;
            }
        }
        .link:last-child{
            border-bottom: none;
        }
        .image-title{
            display: flex;
            justify-content: start;
            align-items: start;
            img{
                width: 40px;
                height: 40px;
                object-fit: contain;
                margin-right: 1rem;
                margin-top: 0.5rem;
            }
        }
    }
    .contact-btn{
        width: 100%;
        a{
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }
}

.heading{
    padding: 12px 0.5rem;
    background: #FAFAFC;
    p{
        color: #9CA3AF;
        margin: 0;
        text-transform: uppercase;
    }
}

.back-btn-wrapper{
    display: flex; 
    justify-content: start;
    align-items: center;
    padding: 0 0.5rem;
    img{
        margin-right: 0.5rem;
    }
    span{
        color: #111827;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .contact-btn{
        margin-bottom: -0.4rem;
    }    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .contact-btn{
        margin-bottom: -0.4rem;
    }    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .contact-btn{
        margin-bottom: 0.8rem;
    }    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .contact-btn{
        margin-bottom: 1rem;
    }    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .contact-btn{
        margin-bottom: 1rem;
    }    
}