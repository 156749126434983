.cloud-solutions.transformation-wrapper{
    padding: 7rem 0;
    text-align: center;
    background: #fff;
    .container{
        max-width: 800px;
        p{
            max-width: 650px;
            margin: 0 auto;
            margin-top: 2rem;
            margin-bottom: 4rem;
            color: #1F2937;
        }
        .icons{
            margin-top: 3rem;
            .item{
                display: flex;
                flex-flow: column;
                justify-content: space-between;
                h5{
                    margin-top: 1rem;
                }
                a{
                    font-size: 14px;
                    margin-top: 1rem;
                }
            }
        }
    }
}