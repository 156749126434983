// .project-operations-main-wrapper{
//     .hero-wrapper{
//         min-height: calc(100vh - 133px);
//         background: #EEF3FD;
//         background-image: url('../../../../public/supply-chain/bg.svg');
//         background-position: bottom center;
//         background-repeat: no-repeat;
//         background-size: 100%;
//         display: flex;
//         align-items: center;
//         .content{
//             text-align: center;
//             margin-top: -4rem;
//             img{
//                 width: 50px;
//                 height: 50px;
//                 object-fit: contain;
//                 margin-bottom: 1.5rem;
//             }
//             p{
//                 color: #1D68F0;
//             }
//             h1{
//                 color: #111827;
//             }
//         }   
//     }
    
//     .section-headings{
//         background-color: #030712;
//         // margin-bottom: 1rem;
//         position: sticky;
//         top: 0;
//         z-index: 100000;
//         .items{
//             margin: 0 auto;
//             display: flex;
//             justify-content: start;
//             align-items: center;
//             overflow-x: auto;
//             a{
//                 padding: 1.4rem 0;
//                 border-bottom: solid;
//                 border-width: 4px;
//                 border-color: transparent;
//                 color: #9CA3AF;
//                 white-space: nowrap;
//                 margin: 0 2rem;
//             }
//             a.active{
//                 color: #fff;
//                 border-color: #1D68F0;
//             }
//             a:hover{
//                 transition: all .3s ease;
//                 color: #fff;
//                 border-color: #1D68F0;
//             }
//         }
//     }
    
//     .overview{
//         padding: 8rem 0;
//         background-color: #fff;
//         background-image: url(../../../../public//supply-chain/overview-bg.png);
//         background-size: contain;
//         background-position: bottom right;
//         background-repeat: no-repeat;
//         p.subtitle{
//             color: #1D68F0;
//             text-transform: uppercase;
//             margin-bottom: 1.5rem;
//             font-weight: 600;
//         }
//         h2{
//             margin-bottom: 1.5rem;
//         }
//         .content p{
//             max-width: 680px;
//         }
//     }
    
//     .questions{
//         background-color: #1F2937;
//         padding: 6rem 0;
//         h2{
//             color: #fff;
//             margin-bottom: 3rem;
//             text-align: center;
//         }
//         .tiles{
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             width: 100%;
//             overflow-x: auto;
//             flex-wrap: wrap;
//             .tile{
//                 width: 300px;
//                 border-radius: 12px;
//                 background-color: #030712;
//                 padding: 2.5rem;
//                 svg{
//                     margin-bottom: 1.5rem;
//                 }
//                 h4{
//                     color: #fff;
//                     line-height: 2.2rem;
//                 }
//             }
//             .tile-smaller{
//                 opacity: 0.4;
//                 transform: scale(0.8);
//             }
//         }
//     }
    
//     .challenges{
//         padding: 7rem 0;
//         .right-content{
//             p{
//                 color: #1D68F0;
//                 font-weight: 600;
//             }
//             h3{
//                 font-weight: 600;
//             }
//         }
//         .left-content{
//             .item{
//                 max-width: 70%;
//                 display: flex;
//                 align-items: start;
//                 margin-bottom: 2rem;
//                 svg{
//                     margin-right: 1rem;
//                 }
//                 p{
//                     font-weight: 400;
//                 }
//             }
//         }
//         .challenge-image{
//             margin: 0 auto;
//             width: 100%;
//         }
//     }
    
//     .dynamics{
//         padding: 7rem 0;
//         background: #FAFAFC;
//         text-align: center;
//         .icon{
//             margin-bottom: 2rem;
//         }
//         small{
//             display: block;
//             font-weight: 600;
//             text-transform: uppercase;
//             color: #1D68F0;
//             margin-bottom: 1.5rem;
//         }
//         h3{
//             color: #111827;
//             margin-bottom: 2rem;
//         }
//         p{
//             max-width: 750px;
//             margin: 0 auto;
//             margin-bottom: 4rem;
//         }
//         .video{
//             margin: 0 auto;
//             margin-top: 2rem;
//             position: relative;
//             max-width: 1000px;
//             width: 100%;
//             max-height: 541px;
//             height: 100%;
//             border-radius: 14px;
//             overflow: hidden;
//             .placeholder-image{
//                 width: 100%;
//                 position: relative;
//                 z-index: 100;
//             }
//             .play-button{
//                 position: absolute; 
//                 left: 0;
//                 right: 0;
//                 top: 40%;
//                 bottom: 0;
//                 margin: 0 auto;
//                 z-index: 10000;
//                 width: 87px;
//                 height: 87px;
//                 object-fit: contain;
//                 cursor: pointer;
//                 filter: drop-shadow(5px 15px 12px #00000070);
//                 transition: all .3s ease;
//             }
//             .play-button:hover{
//                 transform: scale(0.95);
//             }
//         }
//     }
    
//     .efficiency{
//         padding: 2rem 0;
//         padding-bottom: 4rem;
//         background: #fff;
//         .banner{
//             margin-top: 4rem;
//             padding: 36px 64px 36px 64px;
//             background: #001D6C;
//             border-radius: 12px;
//             color: white;
//             .left{
//                 width: 100%;
//                 height: 100%;
//                 display: flex;
//                 align-items: center;
//                 justify-content: start;
//             }
//             .right{
//                 width: 100%;
//                 height: 100%;
//                 text-align: right;
//                 display: flex;
//                 align-items: center;
//                 justify-content: end;
//             }
//         }
//     }
    
//     .supply-benefits-wrapper{
//         background: #fff;
//         padding: 8rem 0;
//         padding-bottom: 2rem;
//         .header-content{
//             text-align: center;
//             small{
//                 display: block;
//                 font-weight: 600;
//                 text-transform: uppercase;
//                 color: #1D68F0;
//                 margin-bottom: 1.5rem;
//             }
//             h3{
//                 color: #111827;
//                 margin-bottom: 2rem;
//             }
//             p{
//                 max-width: 750px;
//                 margin: 0 auto;
//                 margin-bottom: 4rem;
//                 color: #1F2937;
//             }
//         }
//         .benefit-item{
//             margin-top: 2rem;
//             svg{
//                 margin-bottom: 1.4rem;
//             }
//             h4{
//                 font-weight: 600;
//                 max-width: 75%;
//             }
//             p{
//                 width: 85%;
//                 color: #4B5563;
//                 font-weight: 400;
//                 max-width: 290px;
//             }
//         }
//     }
    
//     .streamline-banner{
//         background: #064BD3;
//         .banner{
//             // margin-top: 4rem;
//             padding: 52px 0px 52px 0px;
//             border-radius: 12px;
//             color: white;
//             .left{
//                 width: 100%;
//                 height: 100%;
//             }
//             .right{
//                 width: 100%;
//                 height: 100%;
//                 text-align: right;
//                 display: flex;
//                 align-items: center;
//                 justify-content: end;
//                 button:hover{
//                     svg{
//                         path{
//                             fill: #fff;
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     .how-help{
//         background: url(../../../../public/project-operations/help-bg.png);
//         background-repeat: no-repeat;
//         background-position: bottom right;
//         background-size: contain;
//         p.subtitle{
//             color: #1D68F0;
//             text-transform: uppercase;
//             margin-bottom: 1.5rem;
//             font-weight: 600;
//         }
//         h2{
//             margin-bottom: 1.5rem;
//             max-width: 500px;
//         }
//         .content p{
//             max-width: 80%;
//         }
//     }
    
//     .how-help{
//         padding: 4rem 0;
//         padding-top: 5rem;
//     }
    
//     .process-slider-features{
//         padding-top: 4rem;
//         padding-bottom: 7rem;
//         .feature-item{
//             h4{
//                 // max-width: 300px;
//                 line-height: 28px;
//                 letter-spacing: -1%;
//             }
//             p{
//                 // max-width: 350px;
//                 color: #1F2937;
//                 font-size: 14px;
//             }
//         }
//     }
    
//     .success-story-wrapper{
//         background: #EEF3FD;
//         background-image: url(../../../../public/supply-chain/success-story-bg.png);
//         background-repeat: no-repeat;
//         background-position: bottom right;
//         background-size: contain;
    
//         p.subtitle{
//             color: #1D68F0;
//             text-transform: uppercase;
//             margin-bottom: 1.5rem;
//             font-weight: 600;
//             font-size: 12px;
//         }
//         h2{
//             margin-bottom: 1.5rem;
//             max-width: 500px;
//         }
//         .content p{
//             max-width: 80%;
//             line-height: 30.6px;
//             font-size: 18px;
//             margin-bottom: 3rem;
//         }
//     }
    
//     .success-story-wrapper{
//         padding: 4rem 0;
//         padding-top: 5rem;
//     }
    
//     .transformation-wrapper{
//         padding: 7rem 0;
//         text-align: center;
//         background: #fff;
//         .container{
//             max-width: 800px;
//             p{
//                 max-width: 650px;
//                 margin: 0 auto;
//                 margin-top: 2rem;
//                 margin-bottom: 4rem;
//                 color: #1F2937;
//             }
//             .icons{
//                 margin-top: 3rem;
//                 .item{
//                     display: flex;
//                     flex-flow: column;
//                     justify-content: space-between;
//                     h5{
//                         margin-top: 1rem;
//                     }
//                     a{
//                         font-size: 14px;
//                         margin-top: 1rem;
//                     }
//                 }
//             }
//         }
//     }
    
//     .light-contact-form{
//         background-color: '#FAFAFC';
//         background-image: url(../../../../public/supply-chain/contact-form-bg.svg);
//         .sec-12-heading{
//             color: #111827;
//         }
//         .sec-12-desc{
//             color: #111827;
//         }
//         .sec-11-contact-card{
//             background: #FFFFFF;
//         }
//     }

//     .get-in-touch-wrapper{
//         padding: 8rem 0;
//         background-image: url(../../../../public/project-operations/get-in-touch.png);
//         background-position: left bottom;
//         background-repeat: no-repeat;
//         background-size: 40%;
//         .left-content{
//             padding-bottom: 3rem;
//             // min-height: 500px;
//         }
//         .right-content{
//             padding-bottom: 3rem;
//         }
//     }
    
//     // media queries
//     /* Extra small devices (phones, 600px and down) */
//     @media only screen and (max-width: 600px) {
//         .dynamics{
//             .video{
//                 border-radius: 0px;
//                 .play-button{
//                     position: absolute; 
//                     left: 0;
//                     right: 0;
//                     top: 37%;
//                     bottom: 0;
//                     margin: 0 auto;
//                     z-index: 10000;
//                     width: 65px;
//                     height: 65px;
//                     object-fit: contain;
//                     cursor: pointer;
//                     filter: drop-shadow(5px 15px 12px #00000070);
//                     transition: all .3s ease;
//                 }
//             }
//         }
//         .challenges{
//             .left-content{
//                 .item{
//                     max-width: 100%;
//                 }
//             }
//         }
//         .efficiency{
//             .banner{
//                 .right{
//                     margin-top: 1rem;
//                     justify-content: start;
//                 }
//             }
//         }
//         .streamline-banner{
//             .banner{
//                 .right{
//                     margin-top: 1rem;
//                     justify-content: start;
//                 }
//             }
//         }
//         .how-help{
//             h2{
//                 margin-bottom: 1.5rem;
//                 max-width: 100%;
//             }
//             .content p{
//                 max-width: 100%;
//             }
//         }
//         .process-slider-features{
//             .feature-item{
//                 display: block;
//                 align-items: start;
//                 justify-content: start;
//                 margin-bottom: 1.5rem;
//                 img{
//                     margin-right: 2rem;
//                 }
//                 h4{
//                     max-width: 100%;
//                 }
//                 p{
//                     max-width: 100%;
//                 }
//             }
//         }
//         .success-story-wrapper{
//             .right-content{
//                 min-height: 350px;
//                 max-height: 350px;
//             }
//         }
//         .efficiency{
//             .banner{
//                 padding: 3rem 2rem;
//                 border-radius: 0;
//             }
//         }
//         .get-in-touch-wrapper{
//             background-size: 100%;
//             .left-content{
//                 min-height: 100px;
//             }
//             .right-content{
//                 min-height: 100px;
//                 padding-bottom: 6rem;
//             }
//         }
//     }
    
//     /* Small devices (portrait tablets and large phones, 600px and up) */
//     @media only screen and (min-width: 600px) {
//         .dynamics{
//             .video{
//                 border-radius: 0px;
//                 .play-button{
//                     position: absolute; 
//                     left: 0;
//                     right: 0;
//                     top: 37%;
//                     bottom: 0;
//                     margin: 0 auto;
//                     z-index: 10000;
//                     width: 65px;
//                     height: 65px;
//                     object-fit: contain;
//                     cursor: pointer;
//                     filter: drop-shadow(5px 15px 12px #00000070);
//                     transition: all .3s ease;
//                 }
//             }
//         }
//         .challenges{
//             .left-content{
//                 .item{
//                     max-width: 100%;
//                 }
//             }
//         }
//         .efficiency{
//             .banner{
//                 .right{
//                     margin-top: 1rem;
//                     justify-content: start;
//                 }
//             }
//         }
//         .streamline-banner{
//             .banner{
//                 .right{
//                     margin-top: 1rem;
//                     justify-content: start;
//                 }
//             }
//         }
//         .how-help{
//             h2{
//                 margin-bottom: 1.5rem;
//                 max-width: 100%;
//             }
//             .content p{
//                 max-width: 100%;
//             }
//         }
//         .process-slider-features{
//             .feature-item{
//                 display: flex;
//                 align-items: start;
//                 justify-content: start;
//                 margin-bottom: 1.5rem;
//                 img{
//                     margin-right: 2rem;
//                 }
//                 h4{
//                     max-width: 100%;
//                 }
//                 p{
//                     max-width: 100%;
//                 }
//             }
//         }
//         .success-story-wrapper{
//             .right-content{
//                 min-height: 350px;
//                 max-height: 350px;
//             }
//         }
//         .efficiency{
//             .banner{
//                 padding: 3rem 2rem;
//                 border-radius: 0;
//             }
//         }
//         .get-in-touch-wrapper{
//             background-size: 60%;
//             .left-content{
//                 min-height: 100px;
//             }
//             .right-content{
//                 min-height: 100px;
//                 padding-bottom: 6rem;
//             }
//         }
//     }
    
//     /* Medium devices (landscape tablets, 768px and up) */
//     @media only screen and (min-width: 768px) {
//         .dynamics{
//             .video{
//                 border-radius: 14px;
//                 padding: 0 2rem;
//                 .play-button{
//                     position: absolute; 
//                     left: 0;
//                     right: 0;
//                     top: 40%;
//                     bottom: 0;
//                     margin: 0 auto;
//                     z-index: 10000;
//                     width: 87px;
//                     height: 87px;
//                     object-fit: contain;
//                     cursor: pointer;
//                     filter: drop-shadow(5px 15px 12px #00000070);
//                     transition: all .3s ease;
//                 }
//             }
//             .challenges{
//                 .left-content{
//                     .item{
//                         max-width: 100%;
//                     }
//                 }
//             }
//         }
//         .efficiency{
//             .banner{
//                 .right{
//                     margin-top: 1rem;
//                     justify-content: start;
//                 }
//             }
//         }
//         .streamline-banner{
//             .banner{
//                 .right{
//                     margin-top: 1rem;
//                     justify-content: start;
//                 }
//             }
//         }
//         .how-help{
//             .content p{
//                 max-width: 100%;
//             }
//         }
//         .how-help{
//             h2{
//                 margin-bottom: 1.5rem;
//                 max-width: 100%;
//             }
//             .content p{
//                 max-width: 100%;
//             }
//         }
//         .process-slider-features{
//             .feature-item{
//                 display: flex;
//                 align-items: start;
//                 justify-content: start;
//                 margin-bottom: 1.5rem;
//                 img{
//                     margin-right: 2rem;
//                 }
//                 h4{
//                     max-width: 100%;
//                 }
//                 p{
//                     max-width: 100%;
//                 }
//             }
//         }
//         .success-story-wrapper{
//             .right-content{
//                 min-height: 1000px;
//                 max-height: 1000px;
//             }
//         }
//         .efficiency{
//             .banner{
//                 padding: 3rem 2rem;
//                 border-radius: 0;
//             }
//         }
//         .get-in-touch-wrapper{
//             background-size: 60%;
//             .left-content{
//                 min-height: 300px;
//             }
//             .right-content{
//                 min-height: 300px;
//                 padding-bottom: 12rem;
//             }
//         }
//     }
    
//     /* Large devices (laptops/desktops, 992px and up) */
//     @media only screen and (min-width: 992px) {
//         .dynamics{
//             .video{
//                 border-radius: 14px;
//                 padding: 0 2rem;
//                 .play-button{
//                     position: absolute; 
//                     left: 0;
//                     right: 0;
//                     top: 40%;
//                     bottom: 0;
//                     margin: 0 auto;
//                     z-index: 10000;
//                     width: 87px;
//                     height: 87px;
//                     object-fit: contain;
//                     cursor: pointer;
//                     filter: drop-shadow(5px 15px 12px #00000070);
//                     transition: all .3s ease;
//                 }
//             }
//             .challenges{
//                 .left-content{
//                     .item{
//                         max-width: 70%;
//                     }
//                 }
//             }
//         }
//         .efficiency{
//             .banner{
//                 .right{
//                     margin-top: 0rem;
//                     justify-content: end;
//                 }
//             }
//         }
//         .streamline-banner{
//             .banner{
//                 .right{
//                     margin-top: 0rem;
//                     justify-content: end;
//                 }
//             }
//         }
//         .process-slider-features{
//             .feature-item{
//                 display: block;
//                 align-items: start;
//                 justify-content: start;
//                 margin-bottom: 1.5rem;
//                 img{
//                     margin-right: 2rem;
//                 }
//                 h4{
//                     max-width: 350px;
//                 }
//                 p{
//                     max-width: 300px;
//                 }
//             }
//         }
//         .success-story-wrapper{
//             .right-content{
//                 min-height: 600px;
//                 max-height: 600px;
//             }
//         }
//         .efficiency{
//             .banner{
//                 padding: 36px 64px 36px 64px;
//                 border-radius: 12px;
//             }
//         }
//         .get-in-touch-wrapper{
//             background-size: 40%;
//             .left-content{
//                 min-height: 500px;
//             }
//             .right-content{
//                 min-height: 500px;
//             }
//         }
//     }
    
//     /* Extra large devices (large laptops and desktops, 1200px and up) */
//     @media only screen and (min-width: 1200px) {
//         .dynamics{
//             .video{
//                 border-radius: 14px;
//                 padding: 0 2rem;
//                 .play-button{
//                     position: absolute; 
//                     left: 0;
//                     right: 0;
//                     top: 40%;
//                     bottom: 0;
//                     margin: 0 auto;
//                     z-index: 10000;
//                     width: 87px;
//                     height: 87px;
//                     object-fit: contain;
//                     cursor: pointer;
//                     filter: drop-shadow(5px 15px 12px #00000070);
//                     transition: all .3s ease;
//                 }
//             }
//         }
//         .challenges{
//             .left-content{
//                 .item{
//                     max-width: 70%;
//                 }
//             }
//         }
//         .efficiency{
//             .banner{
//                 .right{
//                     margin-top: 0rem;
//                     justify-content: end;
//                 }
//             }
//         }
//         .streamline-banner{
//             .banner{
//                 .right{
//                     margin-top: 0rem;
//                     justify-content: end;
//                 }
//             }
//         }
//         .process-slider-features{
//             .feature-item{
//                 display: block;
//                 align-items: start;
//                 justify-content: start;
//                 margin-bottom: 1.5rem;
//                 img{
//                     margin-right: 2rem;
//                 }
//                 h4{
//                     max-width: 350px;
//                 }
//                 p{
//                     max-width: 300px;
//                 }
//             }
//         }
//         .success-story-wrapper{
//             .right-content{
//                 min-height: 600px;
//                 max-height: 600px;
//             }
//         }
//         .efficiency{
//             .banner{
//                 padding: 36px 64px 36px 64px;
//                 border-radius: 12px;
//             }
//         }
//         .get-in-touch-wrapper{
//             background-size: 40%;
//             .left-content{
//                 min-height: 500px;
//             }
//             .right-content{
//                 min-height: 500px;
//             }
//         }
//     }
// }


.get-in-touch-wrapper{
    padding-top: 8rem;
    padding-bottom: 96px;
    // background-image: url(../../../../public/project-operations/get-in-touch.png);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 40%;
    .left-content{
        // padding-bottom: 3rem;
        // min-height: 500px;
    }
    .right-content{
        // padding-bottom: 3rem;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .get-in-touch-wrapper{
        background-size: 100%;
        // .left-content{
        //     min-height: 100px;
        // }
        // .right-content{
        //     min-height: 100px;
        //     padding-bottom: 6rem;
        // }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .get-in-touch-wrapper{
        background-size: 60%;
        // .left-content{
        //     min-height: 100px;
        // }
        // .right-content{
        //     min-height: 100px;
        //     padding-bottom: 6rem;
        // }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .get-in-touch-wrapper{
        background-size: 60%;
        // .left-content{
        //     min-height: 300px;
        // }
        // .right-content{
        //     min-height: 300px;
        //     padding-bottom: 12rem;
        // }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .get-in-touch-wrapper{
        background-size: 40%;
        // .left-content{
        //     min-height: 500px;
        // }
        // .right-content{
        //     min-height: 500px;
        // }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .get-in-touch-wrapper{
        background-size: 40%;
        // .left-content{
        //     min-height: 500px;
        // }
        // .right-content{
        //     min-height: 500px;
        // }
    }
}