/** 
***
***
***
        TEXT COLORS
***
***
***
**/

/* primary color */
.mir-text-primary-100{
    color: #EEF3FD;
}
.mir-text-primary-200{
    color: #D1E5FF;
}
.mir-text-primary-300{
    color: #8EB8F7;
}
.mir-text-primary-400{
    color: #4592F7;
}
.mir-text-primary-500{
    color: #1D68F0;
}
.mir-text-primary-600{
    color: #004BD3;
}
.mir-text-primary-700{
    color: #00319C;
}
.mir-text-primary-800{
    color: #001D6C;
}
.mir-text-primary-900{
    color: #001141;
}
/* neutral color */
.mir-text-neutral-100{
    color: #F7F8FA;
}
.mir-text-neutral-200{
    color: #ECEEF2;
}
.mir-text-neutral-300{
    color: #D6DBE0;
}
.mir-text-neutral-400{
    color: #9CA3AF;
}
.mir-text-neutral-500{
    color: #6B7280;
}
.mir-text-neutral-600{
    color: #4B5563;
}
.mir-text-neutral-700{
    color: #1F2937;
}
.mir-text-neutral-800{
    color: #111827;
}
.mir-text-neutral-900{
    color: #111316;
}
/* success color */
.mir-text-success-100{
    color: #EDFCF2;
}
.mir-text-success-200{
    color: #98E0B2;
}
.mir-text-success-300{
    color: #16A34A;
}
.mir-text-success-400{
    color: #166534;
}
.mir-text-success-500{
    color: #052E16;
}
/* warning color */
.mir-text-warning-100{
    color: #FEF9E7;
}
.mir-text-warning-200{
    color: #F7D076;
}
.mir-text-warning-300{
    color: #F59E0B;
}
.mir-text-warning-400{
    color: #B45309;
}
.mir-text-warning-500{
    color: #6B2F0D;
}
/* error color */
.mir-text-error-100{
    color: #FEEFEF;
}
.mir-text-error-200{
    color: #E96767;
}
.mir-text-error-300{
    color: #DC2626;
}
.mir-text-error-400{
    color: #B91C1C;
}
.mir-text-error-500{
    color: #7F1D1D;
}
/* shades color */
.mir-text-white{
    color: #fff;
}
.mir-text-black{
    color: #000;
}




/** 
***
***
***
        BACKGROUND COLORS
***
***
***
**/

/* primary color */
.mir-bg-primary-100{
    background: #EEF3FD;
}
.mir-bg-primary-200{
    background: #D1E5FF;
}
.mir-bg-primary-300{
    background: #8EB8F7;
}
.mir-bg-primary-400{
    background: #4592F7;
}
.mir-bg-primary-500{
    background: #1D68F0;
}
.mir-bg-primary-600{
    background: #004BD3;
}
.mir-bg-primary-700{
    background: #00319C;
}
.mir-bg-primary-800{
    background: #001D6C;
}
.mir-bg-primary-900{
    background: #001141;
}
/* neutral color */
.mir-bg-neutral-100{
    background: #F7F8FA;
}
.mir-bg-neutral-200{
    background: #ECEEF2;
}
.mir-bg-neutral-300{
    background: #D6DBE0;
}
.mir-bg-neutral-400{
    background: #9CA3AF;
}
.mir-bg-neutral-500{
    background: #6B7280;
}
.mir-bg-neutral-600{
    background: #4B5563;
}
.mir-bg-neutral-700{
    background: #1F2937;
}
.mir-bg-neutral-800{
    background: #111827;
}
.mir-bg-neutral-900{
    background: #111316;
}
/* success color */
.mir-bg-success-100{
    background: #EDFCF2;
}
.mir-bg-success-200{
    background: #98E0B2;
}
.mir-bg-success-300{
    background: #16A34A;
}
.mir-bg-success-400{
    background: #166534;
}
.mir-bg-success-500{
    background: #052E16;
}
/* warning color */
.mir-bg-warning-100{
    background: #FEF9E7;
}
.mir-bg-warning-200{
    background: #F7D076;
}
.mir-bg-warning-300{
    background: #F59E0B;
}
.mir-bg-warning-400{
    background: #B45309;
}
.mir-bg-warning-500{
    background: #6B2F0D;
}
/* error color */
.mir-bg-error-100{
    background: #FEEFEF;
}
.mir-bg-error-200{
    background: #E96767;
}
.mir-bg-error-300{
    background: #DC2626;
}
.mir-bg-error-400{
    background: #B91C1C;
}
.mir-bg-error-500{
    background: #7F1D1D;
}
/* shades color */
.mir-bg-white{
    background: #fff;
}
.mir-bg-black{
    background: #000;
}