.microsoft-purview {
    .sec1-purview-logo {
        height: 90px;
        width: 90px;
    }

    .purview-benefit-icon {
        width: 36px;
        height: 36px;
    }
}