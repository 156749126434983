//Banner section start

.section-with-bg {
  height: auto;
  overflow-x: hidden;

  .banner-text {
    height: 433px;
    padding: 112px;
    position: absolute;
    width: 100%;

    @media (max-width: 575.98px) {
      padding: 36px 16px 112px;
      position: relative;
      z-index: 1;
    }

    h4 {
      margin-bottom: 34px;

      @media (max-width: 575.98px) {
        margin-bottom: 24px;
        font-size: 12px;
        letter-spacing: 0.6px;
      }
    }

    h1 {
      @media (max-width: 575.98px) {
        font-family: 'Inter', sans-serif;
        font-size: 36px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.72px;
      }
    }
  }

  .banner-img-container {
    width: 100%;
    height: 624px;
    margin-left: auto;
    overflow: hidden;
    position: relative;

    @media (max-width: 575.98px) {
      height: 365px;
      margin-top: -130px;
      z-index: 0;
      padding: 8px;
    }

    .section-image {
      position: absolute;
      top: 0;
      right: 0;
      width: 66%;
      height: 100%;
      object-fit: none;
      object-position: left;
      z-index: 2;

      @media (max-width: 575.98px) {
        width: 100%;
        object-fit: cover;
        object-position: center center;
        top: auto;
        transform: scale(1.7);
      }
    }

    .bottom-rect {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 182px;
      z-index: 1;

      @media (max-width: 575.98px) {
        height: 80px;
      }
    }
  }
}


// passion section
.passion-section {
  padding: 96px 112px 32px 112px;

  @media (max-width: 575.98px) {
    padding: 52px 16px;
  }

  .passion-content {
    gap: 32px;

    .passion-title {
      @media (max-width: 575.98px) {
        font-size: 12px;
        letter-spacing: 0.6px;
      }
    }

    .passion-subtitle {
      @media (max-width: 575.98px) {
        margin-top: 16px;
        font-size: 32px;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: -0.64px;
      }
    }

    .passion-text {
      @media (max-width: 575.98px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 170%;
        margin-bottom: 36px;
      }
    }

    @media (max-width: 575.98px) {
      gap: 24px;
    }
  }
}


.success-section {
  height: 713px;
  flex-shrink: 0;
  position: relative;

  @media (max-width: 575.98px) {
    height: 639px;
  }

  .top-clip-path, .bottom-clip-path {
    position: absolute;
    z-index: 2;

    svg {
      width: 274.787px;
      height: 274.31px;

      @media (max-width: 575.98px) {
        width: 120px;
        height: 123px;
      }
    }
  }

  .top-clip-path {
    top: 0;
    left: 0;
  }

  .bottom-clip-path {
    bottom: 0;
    right: 0;
    transform: rotate(0deg);
  }

  .success-container {
    position: relative;
    min-height: 573px;
    border-radius: 24px;
    overflow: hidden;
    margin-top: 69px;
    margin-bottom: 71px;
    background: linear-gradient(57deg, #001141 37.9%, rgba(0, 17, 65, 0.00) 118.58%), url('../../../public/mir-culture/success_banner.png') lightgray 50% / cover no-repeat;
    z-index: 1;

    @media (max-width: 575.98px) {
      height: 587px;
      border-radius: 0;
      margin-top: 23px;
      margin-bottom: 29px;
    }

    .success-content {
      max-width: 520px;
      margin-left: 103px;
      z-index: 2;

      @media (max-width: 575.98px) {
        max-width: 100%;
        padding: 72px 16px 96px;
        margin-left: 0;
        margin-bottom: 36px;
        margin-top: 82px;
      }

      .success-title {
        margin-bottom: 34px;
      }
    }
  }
}


.testimonial-section {
  min-height: 800px;
  padding: 52px 0 96px;
  overflow-x: hidden;

  @media (max-width: 575.98px) {
    padding: 0;
  }

  .testimonial-container {
    gap: 72px;

    @media (max-width: 575.98px) {
      gap: 36px;
      padding: 52px 16px;
    }

    .slider-container {
      .slick-dots {
        margin-top: 72px;
        bottom: auto;

        @media (max-width: 575.98px) {
          margin-top: 52px;
        }

        li {
          button:before {
            font-size: 12px;
          }

          &.slick-active button:before {
            color: #1D68F0 !important;
          }
        }
      }

      .slick-track {
        display: flex;
        align-items: stretch;
        gap: 64px;

        @media (max-width: 575.98px) {
          gap: 0;
        }
      }

      .slick-slide {
        display: flex;
        justify-content: center;
        align-items: stretch;
        height: auto !important;
      }

      .slider-card {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center;
        height: 100%;
        gap: 52px;
        padding: 72px 96px;
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0 8px 12px rgba(0, 19, 58, 0.06), 0 0 23px rgba(0, 19, 58, 0.04);
        z-index: 1;

        @media (max-width: 575.98px) {
          padding: 52px 24px;
        }

        .testimonial-top {
          gap: 36px;

          svg {
            width: 29px;
            height: 19.079px;
          }
        }

        .testimonial-content {
          text-align: center;
          flex-grow: 1;

          @media (max-width: 575.98px) {
            font-size: 16px;
            font-weight: 500;
            line-height: 170%;
          }
        }

        .testimonial-profile {
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 575.98px) {
            flex-direction: column;
          }

          .profile-img {
            width: 56px;
            height: 56px;
            background-color: #D6DBE0;
            object-fit: cover;
          }

          .profile-info {
            margin-left: 15px;

            @media (max-width: 575.98px) {
              margin-left: 0;
              text-align: center;
            }
          }
        }
      }
    }
  }
}


.events-section {
  background-color: white;
  overflow-x: hidden;

  @media (max-width: 575.98px) {
    margin-top: 52px;
  }

  .events-top {
    padding: 112px 112px 52px;

    @media (max-width: 575.98px) {
      padding: 52px 16px 36px;
    }
  }

  .section-card-deck {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 91px;
    padding: 52px 112px 0;

    @media (max-width: 768px) {
      padding: 30px 15px 0;
      gap: 52px;
    }
  }

  .swipe-deck {
    padding: 91px 112px 112px;

    @media (max-width: 768px) {
      padding: 30px 15px 0;
      gap: 52px;
    }
  }

  .section-intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;

    .title {
      @media (max-width: 575.98px) {
        font-size: 36px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.72px;
      }
    }

    .description {
      @media (max-width: 575.98px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 170%;
      }
    }

    @media (max-width: 575.98px) {
      gap: 24px;
    }
  }

  .section-cards {
    background-color: white;
    width: 100%;

    .section-title {
      margin-bottom: 17px;
    }

    .section-border {
      border-bottom: 1px solid #D6DBE0;
      margin-bottom: 36px;
    }

    .cards-container {
      display: flex;
      justify-content: start;
      gap: 32px;
      overflow: hidden;
      //height: 335px;
      //
      @media (max-width: 575.98px) {
        flex-direction: column;
        //height: 163.249px;
      }


      .event-card {
        width: 100%;
        height: 335px;

        @media (max-width: 575.98px) {
          height: 163.249px;
        }

      }
    }
  }
}

.gradient-overlay-black {
  position: relative;
  border-radius: 12px;
  height: 100%;

  .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 91%);
    z-index: 1;
    border-radius: 12px;
  }

  .overlay-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    //padding: 30px;
    display: flex !important;
    justify-content: space-between !important;
    z-index: 2;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 29px;

    //display: flex;
    align-items: flex-end !important;

    .text-container, .play-button-container {
      display: flex;
      align-items: center;
      flex: 1;

      &:first-child {
        justify-content: start;
        padding-right: 10px;
      }

      &:last-child {
        justify-content: end;
        padding-left: 10px;
      }
    }
  }

  @media (max-width: 575.98px) {
    width: 100%;
    height: 100%;
    margin-left: 0;

    .overlay-content {
      padding-left: 19px;
      padding-right: 19px;
      padding-bottom: 16px;

      .text-container, .play-button-container {
        padding: 0;
      }
    }
  }
}

.swipe-slider {
  .slick-track {
    justify-content: space-between;
    height: 335px;

    @media (max-width: 575.98px) {
      height: 163.249px;
    }

  }

  .slick-track .slick-slide, .slick-track .slick-cloned {
    opacity: 1 !important;
    transform: scale(1) !important;
    transition: all 0.3s ease;
  }

  .slick-slide {
    padding: 0 16px; /* Adds spacing between slides */
    height: 100%;

    > div {
      height: 100%;
    }
  }

  .slick-list {
    margin: 0 -16px; /* Negates the padding for the first and last slides */
  }
}


.women-empowerment-section {
  overflow-x: hidden;

  .empowerment-container {
    padding: 112px 112px 0;

    @media (max-width: 575.98px) {
      padding: 52px 16px;
    }
  }

  .women-empowerment-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .women-empowerment-badges {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 52px;
    margin-top: 52px;

    @media (max-width: 575.98px) {
      flex-direction: column;
      gap: 36px;
      margin-top: 36px;
      justify-content: flex-start;
      align-items: center;
      padding: 0 20%;
    }

    .badge-item {
      display: flex;
      align-items: center;
      gap: 16px;
      color: var(--Grey-20, #333);
      justify-content: center;
    }

    svg {
      height: 24px;
      width: auto;
    }
  }

  .f-section-image-container {
    position: relative;
    overflow: hidden;
    margin-top: 60px;

    img {
      width: 100%;
      object-fit: cover;
      margin-top: 20px;

      @media (max-width: 575.98px) {
        transform-origin: center;
        transform: scale(1.52);
        margin-top: 40px;
      }
    }
  }
}

.team-section {
  padding: 152px 112px 124px;

  .row {
    gap: 24px;
  }

  .team-section-heding {
    text-transform: uppercase;
    margin-bottom: 3px;
  }

  @media (max-width: 575.98px) {
    padding: 72px 16px;

    .team-section-heading,
    .team-section-content {
      font-style: normal;
    }

    .team-section-heading {
      font-size: 12px;
      font-weight: 600;
      line-height: 150%; /* 18px */
      letter-spacing: 0.6px;
    }

    .team-section-content {
      font-size: 24px;
      font-weight: 500;
      line-height: 140%; /* 33.6px */
      letter-spacing: -0.48px;
    }
  }
}


.departments-section {
  overflow: hidden;
  .departments-container {
    gap: 72px;

    @media (max-width: 575.98px) {
      gap: 36px;
      padding: 52px 16px;
    }

    .slick-track {
      align-items: center;
      justify-content: center;
      height: 470px;
      gap: 24px;

      @media (max-width: 575.98px) {
        height: 264px;
      }
    }

    .slick-track .slick-slide, .slick-track .slick-cloned {
      opacity: 1 !important;
      transform: scale(1) !important;
      transition: all 0.3s ease;
    }

    .slick-slide {
      > div {
        height: 100%;
      }
    }

    .slider-card {
      height: 100%;
      gap: 52px;

      @media (max-width: 575.98px) {

      }

      .image-container {
        height: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 12px;

          @media (max-width: 575.98px) {
            border-radius: 6.74px;
          }
        }

        .caption-overlay {
          position: absolute;
          bottom: 0;
          margin-left: 31px;
          margin-bottom: 24px;
          display: inline-flex;
          padding: 6.74px 17.974px;
          align-items: flex-start;
          gap: 5.617px;
          border-radius: 100px;
          background: rgba(192, 192, 192, 0.30);
          box-shadow: 0px 4px 9.6px 0px rgba(0, 0, 0, 0.10);
          backdrop-filter: blur(10px);

          @media (max-width: 575.98px) {
            margin-left: 15px;
            margin-bottom: 13px;
            font-size: 11.234px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 15.728px */
            letter-spacing: -0.112px;
          }
        }
      }
    }
  }
}

.map-section {
  padding-top: 110px;

  @media (max-width: 575.98px) {
    .map-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 140%; /* 28px */
      letter-spacing: -0.4px;
    }
  }
}

.map-container {
  position: relative;

  .map-svg {
    padding: 0 69px;
  }

  .overlay-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 88px;

    .top-clip-art,
    .bottom-clip-art {
      position: absolute;
    }

    .top-clip-art {
      top: 25px;
      left: 0;
    }

    .bottom-clip-art {
      right: -2.333px;
      bottom: -2px;
    }

    @media (max-width: 575.98px) {
      margin-bottom: 62px;
      gap: 0;

      .top-clip-art,
      .bottom-clip-art {
        display: none;
      }
    }
  }

  .content-box {
    width: 80%;
    color: white;
    text-align: center;
    border-radius: 24px;
    background: linear-gradient(172deg, #004BD3 -22.23%, #00229C 98.7%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 84px 215px;
    gap: 64px;
    position: relative;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    @media (max-width: 575.98px) {
      padding: 52px 24px;
      gap: 36px;

      .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 170%; /* 23.8px */
      }
    }
  }

  .button-container {
    display: grid;
    gap: 10px;
  }

  .reachout-btn:hover {
    background: white !important;
    border: 1px solid #1D68F0 !important;
    color: #1D68F0 !important;
  }
}








