.sm-visible {
    display: none;
}

.ms-images {
    text-align: right;
}

.footer {
    padding-top: 96px;
    padding-bottom: 96px;
    background: var(--neutral-900, #030712);
}

.subscribe-btn {
    text-align: center;
    display: inline-block;
    margin-top: 9%;
}

.divider {
    height: 1px;
    background: var(--neutral-700, #1F2937);
    margin-top: 64px;
    margin-bottom: 64px;
}

.divider-2 {
    height: 1px;
    background: var(--neutral-700, #1F2937);
    margin-top: 64px;
    margin-bottom: 48px;
}

.divider-3 {
    height: 1px;
    background: var(--neutral-700, #1F2937);
    margin-top: 48px;
    margin-bottom: 32px;
}

.menu-header {
    margin-bottom: 8px;
    color: var(--neutral-100, #FAFAFC);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.menu-item {
    color: var(--neutral-400, #9CA3AF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    margin-top: 16px;
    transition: all .3s ease;
    cursor: pointer;
    :hover{
        color: white;
        transition: all .3s ease;
    }
    a{
        color: inherit;
    }
}

.offices {
    margin-top: 52px;
}

.menu-office-header {
    color: var(--neutral-100, #FAFAFC);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.7px;
    margin-top: 24px;
    margin-bottom: 8px;
    transition: all .3s ease;
    cursor: pointer;
    :hover{
        color: white;
        transition: all .3s ease;
    }
    a{
        color: inherit;
    }
}

.menu-office-content {
    margin-bottom: 8px;
    color: var(--neutral-400, #9CA3AF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    transition: all .3s ease;
    cursor: pointer;
    :hover{
        color: white;
        transition: all .3s ease;
    }
    a{
        color: inherit;
    }
}

img {
    vertical-align: top;
}

.img1 {
    width: 98.397px;
    height: 59.998px;
    margin-left: 16px;
}

.img2 {
    width: 97.997px;
    height: 51.598px;
}

.footer-legal-links {
    margin-left: 24px;
}

.social-media-links {
    svg {
        margin-left: 12px;
    }
}

@media screen and (max-width: 400px) {
    .footer {
        padding-top: 64px !important;
        padding-bottom: 64px !important;
    }

    .mir-heading-l2-l1 {
        font-size: 20px !important;
        font-weight: 600 !important;
        line-height: 140% !important;
        letter-spacing: -0.2px !important;
    }

    .subscribe-btn {
        width: 100% !important;
        text-align: center !important;
        display: block !important;
        margin-top: 0 !important;
    }

    .divider {
        margin-top: 52px !important;
        margin-bottom: 52px !important;
    }

    .logo {
        // margin-bottom: 72px;
    }

    .menu-header {
        margin-top: 72px !important;
    }

    .img1 {
        display: none;
    }

    .img2 {
        margin-top: 24px !important;
    }

    .ms-images {
        text-align: left;
    }

    .sm-visible {
        display: block !important;
        margin-top: 8px;
    }

    .footer-legal-links {
        margin-left: 0 !important;
        margin-top: 16px !important;
    }

    .social-media-links {
        svg {
            margin-left: 0px !important;
            margin-top: 32px !important;
        }
    }
}