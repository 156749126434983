.question-slider-wrapper{
    background-color: #030712;
    padding: 6rem 0;
    h2{
        color: #fff;
        margin-bottom: 2rem;
        text-align: center;
    }
    .tile{
        width: 300px;
        border-radius: 12px;
        background-color: #1F2937;
        padding: 2.5rem;
        svg{
            margin-bottom: 1.5rem;
        }
        h5{
            color: #fff;
            line-height: 33.6px;
            letter-spacing: -2%;
            font-size: 24px;
        }
    }
    .slick-track{
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        .slick-slide, 
        .slick-cloned{
            opacity: 0.25;
            transform: scale(0.90);
            transition: all .3s ease;
            .tile{
                h5{
                    font-size: 12px;
                    line-height: 160%;
                    // max-width: 200px;
                    // width: 100%;
                }
            }
        }
        .slick-cloned.slick-current, 
        .slick-slide.slick-current{
            opacity: 1;
            transform: scale(1);
            transition: all .3s ease;
            // width: 110%;
            .tile{
                h5{
                    font-size: 24px;
                    line-height: 33.6px;
                    // max-width: 350px;
                    // width: 100%;
                }
            }
        }
    }

    .slick-dots{
        margin-top: 1rem;
        li{
            margin: 0 12px;
            height: 3px;
            button{
                width: 40px;
                height: 3px;
            }
            button:before{
                content: '';
                width: 40px;
                height: 2px;
                background: #ffffff60;
            }
        }   
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .question-slider-wrapper{
        .slick-slide.slick-current .tile{
            padding: 1.75rem 1.75rem;
            h5{
                font-size: 18px !important;
                line-height: 140% !important;
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .question-slider-wrapper{
        .slick-slide.slick-current .tile{
            h5{
                font-size: 18px !important;
                line-height: 140% !important;
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .question-slider-wrapper{
        .slick-slide.slick-current .tile{
            h5{
                font-size: 24px !important;
                line-height: 33.6px !important;
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .question-slider-wrapper{
        .slick-slide.slick-current .tile{
            h5{
                font-size: 24px !important;
                line-height: 33.6px !important;
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .question-slider-wrapper{
        .slick-slide.slick-current .tile{
            h5{
                font-size: 24px !important;
                line-height: 33.6px !important;
            }
        }
    }
}