.sticky-wrapper {
    background: var(--ui-primary-700, #00319C);
    padding: 16px 36px 16px 36px;
    width: 100%;
}

.schedule-button {
    margin-left: 12px;
}

.schedule-button {
    cursor: pointer;
}