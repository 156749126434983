@import '~bootstrap/scss/bootstrap.scss';
@import '~mir-global-style';
// @import url("http://fonts.googleapis.com/css?family=Inter:400,300,700");

// #scroll {

//     right: 30px;
//     bottom: 30px;

//     a {
//         position: fixed;
//         // left: 100px;
//         cursor: pointer;
//         width: 50px;
//         height: 50px;
//         background-color: #1d08db;
//         text-indent: -9999px;
//         display: none;
//         -webkit-border-radius: 60px;
//         -moz-border-radius: 60px;
//         border-radius: 60px;
//     }
// }

$primary: rgb(0 49 156);

.fab-container {
    z-index: 999;
    position: fixed;
    bottom: 50px;
    right: 50px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    user-select: none;
    // position: absolute;
    bottom: 30px;
    right: 30px;

    &:hover {
        height: 100%;

        .sub-button:nth-child(2) {
            transform: translateY(-60px);
        }

        .sub-button:nth-child(3) {
            transform: translateY(-110px);
        }

        .sub-button:nth-child(4) {
            transform: translateY(-160px);
        }

        .sub-button:nth-child(5) {
            transform: translateY(-210px);
        }

        .sub-button:nth-child(6) {
            transform: translateY(-260px);
        }
    }

    .fab {
        position: relative;
        height: 50px;
        width: 50px;
        background-color: $primary;
        border-radius: 50%;
        z-index: 2;

        // &::before {
        //     content: " ";
        //     position: absolute;
        //     bottom: 0;
        //     right: 0;
        //     height: 35px;
        //     width: 35px;
        //     background-color: inherit;
        //     border-radius: 0 0 10px 0;
        //     z-index: -1;
        // }

        .fab-content {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            border-radius: 50%;

            .material-icons {
                color: white;
                // font-size: 48px;
            }
        }
    }

    .sub-button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 10px;
        right: 10px;
        height: 30px;
        width: 30px;
        background-color: $primary;
        border-radius: 50%;
        transition: all .3s ease;

        svg {
            height: 20px;
            width: 20px;
        }

        &:hover {
            cursor: pointer;
        }

        .material-icons {
            color: white;
            padding-top: 6px;
        }
    }
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.fade.modal-backdrop{
    z-index: 100000000 !important;
}

.modal{
    z-index: 10000000000000 !important;
}

.Toastify__toast-container{
    z-index: 100000000000000;
}

.partner-image {
    height: 90px;
}

.partner-image-media {
    max-height: 60px;
}

.partner-image-border {
    height: 90px;
    border: 1px solid #656A761A;
    background: linear-gradient(149deg, rgba(239, 239, 241, 0.27) 10.79%, rgba(239, 239, 241, 0) 76.87%);
    padding: 3%;
}

.azure-aws-image {
    height: 100px;
}