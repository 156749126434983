.supply-chain-main-wrapper {
    .process-slider-features {
        padding-top: 0rem;
        padding-bottom: 20px;

        .feature-item {
            width: 100%;

            h4 {
                // max-width: 300px;
                line-height: 28px;
                letter-spacing: -1%;
            }

            p {
                // max-width: 350px;
                color: #1F2937;
                font-size: 14px;
            }
        }
    }

    .light-contact-form {
        background-color: '#FAFAFC';
        background-image: url(../../../../public/supply-chain/contact-form-bg.svg);

        .sec-12-heading {
            color: #111827;
        }

        .sec-12-desc {
            color: #111827;
        }

        .sec-11-contact-card {
            background: #FFFFFF;
        }
    }

}

// media queries
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .supply-chain-main-wrapper {
        .process-slider-features {
            .feature-item {
                display: block;
                align-items: start;
                justify-content: start;
                margin-bottom: 1.5rem;

                img {
                    margin-right: 2rem;
                }

                h4 {
                    max-width: 100%;
                }

                p {
                    max-width: 100%;
                }
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .supply-chain-main-wrapper {
        .process-slider-features {
            .feature-item {
                display: flex;
                align-items: start;
                justify-content: start;
                margin-bottom: 1.5rem;

                img {
                    margin-right: 2rem;
                }

                h4 {
                    max-width: 100%;
                }

                p {
                    max-width: 100%;
                }
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .supply-chain-main-wrapper {
        .process-slider-features {
            .feature-item {
                display: flex;
                align-items: start;
                justify-content: start;
                margin-bottom: 1.5rem;

                img {
                    margin-right: 2rem;
                }

                h4 {
                    max-width: 100%;
                }

                p {
                    max-width: 100%;
                }
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .supply-chain-main-wrapper {

        .process-slider-features {
            .feature-item {
                display: block;
                align-items: start;
                justify-content: start;
                margin-bottom: 1.5rem;

                img {
                    margin-right: 2rem;
                }

                h4 {
                    max-width: 350px;
                }

                p {
                    max-width: 300px;
                }
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .supply-chain-main-wrapper {
        .process-slider-features {
            .feature-item {
                display: block;
                align-items: start;
                justify-content: start;
                margin-bottom: 1.5rem;

                img {
                    margin-right: 2rem;
                }

                h4 {
                    max-width: 350px;
                }

                p {
                    max-width: 350px;
                }
            }
        }
    }
}