.project-rescue-main-wrapper {

    .benefits-4 {
        // padding-top: 64px;
        padding-bottom: 24px;
    }

    .trusted-text-pr {
        padding-top: 48px;
        padding-bottom: 64px;
        text-transform: uppercase;
    }

    #section-6 {
        .mir-h4-semibold {
            width: auto !important;
            max-width: none !important;
        }
    }

    .c-text-center {
        text-align: center;
    }

    #section-1 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .section-1-pr {
        background-image: url('../../../../public/project-rescue/overview-bg.png');
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 35.6%;
        min-height: 750px;
    }

    .text-left {
        text-align: left !important;
    }

    .bi-wrapper {
        .hero-wrapper {
            // min-height: calc(100vh - 133px);
            background: #EEF3FD;
            background-image: url('../../../../public/supply-chain/bg.svg');
            background-position: bottom center;
            background-repeat: no-repeat;
            background-size: 100%;
            display: flex;
            align-items: center;

            .content {
                text-align: center;
                padding-top: 120px;
                padding-bottom: 160px;

                img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                    margin-bottom: 42px;
                }

                p {
                    margin-bottom: 24px;
                }

                h1 {
                    color: #111827;
                }
            }
        }
    }

    #section-2 {
        padding-top: 80px;
        padding-bottom: 20px;
    }

    .overline {
        text-transform: uppercase;
    }

    .intro-desc {
        margin-top: 24px !important;
        margin-bottom: 24px !important;
    }

    .learn-more {
        margin-right: 8px;
        margin-top: 24px;
    }

    .bi-sec-2-img {
        width: 100%;
        margin-top: 64px;
        margin-bottom: 64px;
    }

    .sec-2-sub {
        padding-top: 80px;
        padding-bottom: 120px;
    }

    .sec-2-sub-desc {
        margin-top: 24px;
        margin-bottom: 16px;
    }

    .sec-2-sub-cards {
        // margin-top: 64px;
        padding-top: 36px;
    }

    .sec-2-sub-numbers {
        margin-right: 16px;
    }

    .sec-2-sub-breakers {
        height: 64px;
        border-bottom: 1px solid var(--neutral-200, #EEEEF2);
    }

    .section-4 {
        padding-top: 32px;
        padding-top: 120px;
    }

    .bi-sec-4-img {
        width: 100%;
    }

    .sec-4-img-desc {
        margin-top: 24px;
        margin-bottom: 52px;
    }

    .checkmark-images {
        width: 28px;
        height: 28px;
    }

    .checkmark-bullets {
        margin: 0;
        padding: 0px 0 0px 30px;
        list-style: none;
        background-image: url('../../../../public/project-rescue/unsatisfied.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 20px;
        margin-top: 24px;
    }

    ul {
        padding-left: 0 !important;
    }

    .benefits-1-bg {
        background-image: url('../../../../public/project-rescue/benefits.png');
        background-repeat: no-repeat;
        background-size: 40% 70%;
        background-origin: content-box;
        background-position: left;
    }

    .sec-4-card-container {
        margin-top: 56px;
    }

    .mir-paragraph-rg-l4-l7 {
        width: auto !important;
    }

    .sec-4-card-header {
        margin-top: 24px;
        line-height: 140% !important;
    }

    .sec-4-card-desc {
        margin-top: 12px;
    }

    .sec-4-revol {
        padding-top: 96px;
        padding-bottom: 64px;
    }

    .sec-4-revol-card {
        padding: 32px;
    }

    .sec-4-revol-card-header {
        margin-top: 24px;
    }

    .sec-4-revol-img {
        width: 100%;
    }

    .sec-4-discover {
        padding-top: 64px;
        padding-bottom: 120px;
    }

    .sec-4-explore-now {
        margin-top: 36px;
    }

    .sec-5-contents {
        margin-top: 80px;
    }

    .sec-5-1-heading {
        margin-bottom: 4px;
    }

    .sec-5-1-card {
        margin-top: 36px;
    }

    .sec-5-1-card-img {
        width: 24px;
        height: 24px;
    }

    .steps {
        margin-top: 96px;
    }

    .advantage {
        padding-top: 16px;
        padding-bottom: 64px;
    }

    .advatage-cards {
        margin-top: 8px;
    }

    .advatage-card {
        margin-top: 64px;
    }

    .advatage-card-desc {
        margin-top: 12px;
    }

    .advatage-card-desc::before {
        content: url('../../../../public/project-rescue/arrow-right.svg');
        margin-right: 16px;
    }

    .simplify-header {
        margin-bottom: 28px;
    }

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .project-rescue-main-wrapper {
        .advatage-card {
            margin-top: 32px !important;
        }

        .c-text-center {
            text-align: left !important;
        }

        .benefits-img {
            margin-bottom: 36px;
        }

        .benefits-1-bg {
            background: none;
        }

        .section-1 {
            background: none !important;
            min-height: 0px !important;
        }

        .ms-fabric {
            text-align: center !important;
        }

        .pl-3 {
            padding-left: 8% !important;
        }

        .bi-wrapper {
            .hero-wrapper {
                .content {
                    padding-top: 52px;
                    padding-bottom: 96px;

                    img {
                        margin-bottom: 42px;
                    }

                    p {
                        font-family: Inter;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 170%;
                        margin: 0 auto;
                        margin-bottom: 24px;
                    }

                    h1 {
                        color: #111827;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 130%;
                        /* 41.6px */
                        letter-spacing: -0.64px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }

}


.project-rescue-main-wrapper {

    .process-slider-features {
        padding-top: 0rem;
        padding-bottom: 120px;

        .feature-item {
            width: 100%;

            h4 {
                // max-width: 300px;
                line-height: 28px;
                letter-spacing: -1%;
            }

            p {
                // max-width: 350px;
                color: #1F2937;
                font-size: 14px;
            }
        }
    }

    .light-contact-form {
        background-color: '#FAFAFC';
        background-image: url(../../../../public/supply-chain/contact-form-bg.svg);

        .sec-12-heading {
            color: #111827;
        }

        .sec-12-desc {
            color: #111827;
        }

        .sec-11-contact-card {
            background: #FFFFFF;
        }
    }

}

// media queries
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .project-rescue-main-wrapper {

        // .dynamics{
        //     .video{
        //         border-radius: 0px;
        //         .play-button{
        //             position: absolute; 
        //             left: 0;
        //             right: 0;
        //             top: 37%;
        //             bottom: 0;
        //             margin: 0 auto;
        //             z-index: 10000;
        //             width: 65px;
        //             height: 65px;
        //             object-fit: contain;
        //             cursor: pointer;
        //             filter: drop-shadow(5px 15px 12px #00000070);
        //             transition: all .3s ease;
        //         }
        //     }
        // }
        // .overview{
        //     background-image: none;
        // }
        // .challenges{
        //     .left-content{
        //         .item{
        //             max-width: 100%;
        //         }
        //     }
        // }
        .process-slider-features {
            .feature-item {
                display: block;
                align-items: start;
                justify-content: start;
                margin-bottom: 1.5rem;

                img {
                    margin-right: 2rem;
                }

                h4 {
                    max-width: 100%;
                }

                p {
                    max-width: 100%;
                }
            }
        }

        // .success-story-wrapper{
        //     .right-content{
        //         min-height: 350px;
        //         max-height: 350px;
        //     }
        // }
        // .efficiency{
        //     .banner{
        //         padding: 3rem 2rem;
        //         border-radius: 0;
        //     }
        // }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .project-rescue-main-wrapper {
        // .overview{
        //     background-image: none;
        // }
        // .challenges{
        //     .left-content{
        //         .item{
        //             max-width: 100%;
        //         }
        //     }
        // }


        .process-slider-features {
            .feature-item {
                display: flex;
                align-items: start;
                justify-content: start;
                margin-bottom: 1.5rem;

                img {
                    margin-right: 2rem;
                }

                h4 {
                    max-width: 100%;
                }

                p {
                    max-width: 100%;
                }
            }
        }

        // .efficiency{
        //     .banner{
        //         padding: 3rem 2rem;
        //         border-radius: 0;
        //     }
        // }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .project-rescue-main-wrapper {
        // .overview{
        //     background-image: none;
        // }


        .process-slider-features {
            .feature-item {
                display: flex;
                align-items: start;
                justify-content: start;
                margin-bottom: 1.5rem;

                img {
                    margin-right: 2rem;
                }

                h4 {
                    max-width: 100%;
                }

                p {
                    max-width: 100%;
                }
            }
        }

        // .efficiency{
        //     .banner{
        //         padding: 3rem 2rem;
        //         border-radius: 0;
        //     }
        // }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .project-rescue-main-wrapper {

        .process-slider-features {
            .feature-item {
                display: block;
                align-items: start;
                justify-content: start;
                margin-bottom: 1.5rem;

                img {
                    margin-right: 2rem;
                }

                h4 {
                    max-width: 350px;
                }

                p {
                    max-width: 300px;
                }
            }
        }

        // .efficiency{
        //     .banner{
        //         padding: 52px 64px 52px 64px;
        //         border-radius: 12px;
        //     }
        // }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .project-rescue-main-wrapper {
        // .challenges{
        //     .left-content{
        //         .item{
        //             max-width: 70%;
        //         }
        //     }
        // }

        .process-slider-features {
            .feature-item {
                display: block;
                align-items: start;
                justify-content: start;
                margin-bottom: 1.5rem;

                img {
                    margin-right: 2rem;
                }

                h4 {
                    max-width: 350px;
                }

                p {
                    max-width: 350px;
                }
            }
        }

        // .efficiency{
        //     .banner{
        //         padding: 52px 64px 52px 64px;
        //         border-radius: 12px;
        //     }
        // }
    }
}