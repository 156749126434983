.sm-visible {
    display: none;
}

.view-all-btn {
    text-align: right;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.p-relative {
    padding: 0 !important;
    position: relative;
}

.home-sec-1-main {
    background: linear-gradient(180deg, rgba(2, 8, 35, 0.00) 0.05%, rgba(2, 8, 35, 0.90) 95.81%), linear-gradient(0deg, rgba(3, 23, 74, 0.20) 0%, rgba(3, 23, 74, 0.20) 100%);
    background-blend-mode: normal, screen;
    position: relative;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(2, 8, 35, 0.00) 0.05%, rgba(2, 8, 35, 0.90) 95.81%), linear-gradient(0deg, rgba(3, 23, 74, 0.20) 0%, rgba(3, 23, 74, 0.20) 100%);
    // height: 886px;
    // background-image: url(./images/home-sec-1bg.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.home-sec-1-main::before {
    background: linear-gradient(180deg, rgba(2, 8, 35, 0.00) 0.05%, rgba(2, 8, 35, 0.90) 95.81%), linear-gradient(0deg, rgba(3, 23, 74, 0.20) 0%, rgba(3, 23, 74, 0.20) 100%);
}

.home-sec-1-main::after {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, rgba(2, 8, 35, 0.00) 0.05%, rgba(2, 8, 35, 0.90) 95.81%), linear-gradient(0deg, rgba(3, 23, 74, 0.20) 0%, rgba(3, 23, 74, 0.20) 100%);
    border-radius: 5px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.home-sec-1 {
    padding-top: 187px;
}

.sec-1-heading {
    background: linear-gradient(282deg, #D6DBE0 35.11%, #F0F7FF 71.95%, #F0F7FF 71.95%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sec-1-header-part-2 {
    padding: 2%;
    margin-top: -2% !important;
    /* background-image: url("https://s3-alpha-sig.figma.com/img/2e6f/d0e4/7fce7236ad28272f1e97e805aa31efe8?Expires=1697414400&Signature=Sav41p67e62pDhpRChKWPFOsvVXOJm9O0ZBAEXxAohL-OYPnV8X53MRqlgWpzkDB0J4aqcI3b-d-mOajHq1x-xPelT1SO2d2H1wnVKFinSOiIJEfWOlFGBQuf3D1EAtlR5RhkuWOPCLL7qKjyduAYvspw3a9-fGVCNjprU1Pq9wRJQzWJJrW5jBH7G6YcPvVZ-ehgzsM~ci~6Yr2TBbo0RBdMLQV0RoflvEuHuFZpq1LT2JFM9ZsBBofucD6Ri7kxLh~zhgqmTLGzFXjwnEWhoTI5S4wzAzAXlRhkN9Bq1bv7zbeVsqT4SYh1cprPhGxlYi9UnfJ-OP9k~deWCvz0w__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"); */
    /* background: radial-gradient(rgb(0, 117, 255), rgb(214, 232, 255));; */
    background: radial-gradient(rgb(0, 117, 255) 9%, rgb(214, 232, 255) 66%);
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    -webkit-font-smoothing: antialiased;
}

.home-sec-1 video {
    width: 100%;
    /* height: 100%; */
    position: absolute;
    object-fit: cover;
    z-index: 0;
}

/* Just styling the content of the div, the *magic* in the previous rules */
.home-sec-1 .container-fluid {
    z-index: 1;
    position: relative;
    text-align: center;
    padding: 10px;
}

video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: scale(1, 1);
}

video::-webkit-media-controls-panel {
    transform: scale(-1, 1);
}

.sec-1-desc-1 {
    color: var(--neutral-100, #FAFAFC);
    text-align: center;
    margin-top: 52px;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.sec-1-actions-group {
    margin-top: 98px;
}

.action-content {
    color: var(--neutral-200, #EEEEF2);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.48px;
}

.action-desc {
    color: var(--neutral-200, #EEEEF2);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
}

.sec-1-actions {
    cursor: pointer;
    margin-top: 8px;
    color: var(--neutral-200, #EEEEF2);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.48px;
}

.action-image {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.home-sec-2-main {
    // height: 1258px;
    padding-bottom: 138px;
    background: linear-gradient(#010722, #000B2C, #030712);
    // background: linear-gradient(180deg, #010722 0%, #000B2C 70.31%, #030712 100%);
    // background: linear-gradient(180deg, #010B2B 0%, rgba(1, 11, 43, 0.00) 45.31%), url(images/sec2-bg.png), lightgray 0px -119.429px / 100% 196.856% no-repeat;
    padding-top: 38px;

    background:
        linear-gradient(to bottom, #010722, #000B2C, #030712, rgba(255, 0, 0, 0)),
        url('images/top-section_up.png');
    width: 100%;
    // background-size: 100% 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // color: white;
    // padding: 20px;
}

// .home-sec-2-main2::after {
//     background: linear-gradient(180deg, #010722 0%, #000B2C 70.31%, #030712 100%);
//     content: '';
//     display: block;
//     height: 100%;
//     position: absolute;
//     width: 100%;
// }

.sec-2-h2 {
    margin-top: 48px;
}


.circle-1 {
    margin-top: 168px;
    // padding: 5%;
    // padding: calc(100% - 702.27px);
    // display: inline-block;
    position: relative;
    z-index: 0;
}

.circle-1::after {
    padding-top: 2px;
    padding-left: 2px;
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    // padding: 1px;
    border-radius: 100%;
    background: linear-gradient(to bottom, #2A69CE, #2A69CE00);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.circle-2 {
    margin: 15%;
    width: 70%;
    height: 70%;
    // display: inline-block;
    position: relative;
    z-index: 0;
}

.circle-2:before {
    // margin: calc(100% - 664.27px);
    aspect-ratio: 1;
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    // padding: 1px;
    padding-right: 2px;
    padding-top: 2px;
    border-radius: 100%;
    background: linear-gradient(to bottom, #2A69CE, #2A69CE00);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.circle-2 {
    margin: 15%;
    width: 70%;
    height: 70%;
    // width: 539.27px;
    // height: 539.27px;
    // flex-shrink: 0;
    // border-radius: 50%;
    // border-image: radial-gradient(#6590FF, #0047FF00);
}

.circle-contents-1 {
    position: absolute;
    top: 8%;
    left: -5%;
}

.circle-contents-2 {
    position: absolute;
    top: 77px;
    left: 80%;
    width: max-content;
}

.circle-contents-3 {
    position: absolute;
    top: 598px;
    left: -4%;
    width: max-content;
}

.circle-contents-4 {
    position: absolute;
    top: -28%;
    left: 26%;
    width: max-content;
}

.circle-contents-5 {
    position: absolute;
    top: 240px;
    left: -34%;
    width: max-content;
}

.circle-contents-6 {
    position: absolute;
    top: 150px;
    left: 86%;
    width: max-content;
}

.circle-contents-7 {
    position: absolute;
    top: 590px;
    left: 77%;
    width: max-content;
}

.circle-content-desc {
    border-radius: 113px;
    background: linear-gradient(50% 50% at 50% 50%, #010B2B 81.77%, rgba(1, 11, 43, 0.00) 100%);
    padding: 14px 0px 20px 0px;
    margin-top: 12px;
    text-align: right;
}

.circle-content-desc-2 {
    border-radius: 113px;
    padding: 14px 0px 20px 0px;
    background: linear-gradient(50% 50% at 50% 50%, #010B2B 81.77%, rgba(1, 11, 43, 0.00) 100%);
    margin-top: 22px;
    text-align: left;
}

.circle-content-desc-3 {
    border-radius: 113px;
    padding: 14px 0px 20px 0px;
    background: linear-gradient(50% 50% at 50% 50%, #010B2B 81.77%, rgba(1, 11, 43, 0.00) 100%);
    margin-top: 12px;
    text-align: left;
}

.middle-circle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle-content-desc-4 {
    border-radius: 113px;
    background: linear-gradient(50% 50% at 50% 50%, #010B2B 81.77%, rgba(1, 11, 43, 0.00) 100%);
    margin-bottom: 15px;
    text-align: center;
}

.circle-content-desc-5 {
    border-radius: 113px;
    padding: 14px 0px 20px 0px;
    // background: radial-gradient(50% 50% at 50% 50%, #010B2B 81.77%, rgba(1, 11, 43, 0.00) 100%);
    margin-top: 15px;
    text-align: right;
}

.circle-content-desc-6 {
    border-radius: 113px;
    padding: 14px 88px 20px 0px;
    // background: radial-gradient(50% 50% at 50% 50%, #010B2B 81.77%, rgba(1, 11, 43, 0.00) 100%);
    margin-top: 30px;
    // text-align: left;
}

.circle-content-desc-7 {
    border-radius: 113px;
    padding: 14px 0px 20px 0px;
    // background: radial-gradient(50% 50% at 50% 50%, #010B2B 81.77%, rgba(1, 11, 43, 0.00) 100%);
    margin-top: 25px;
    text-align: right;
}

.circle-nested-circle {
    display: flex;
    width: 112px;
    height: 112px;
    padding: 24px 18px 24px 24px;
    justify-content: center;
    align-items: center;
    gap: 6.948px;
    flex-shrink: 0;
    border-radius: 92.414px;
    border: 1px solid #2A68CE;
    background: rgba(1, 15, 59, 0.55);
    backdrop-filter: blur(5px);
}

.circle-center-heading {
    // margin-top: 153.29px;
    margin-top: 240.29px;
}

.btn-ms-365-main {
    display: inline-flex;
    padding: 16px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 73px;
    border: 1px solid rgba(32, 80, 184, 0.00);
    background: linear-gradient(178deg, #000F35 1.53%, #03174A 115.73%);
    color: var(--brand-primary-200, #D6E8FF);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    margin-top: 36px;
}

.sec-3 {
    z-index: 1;
    position: absolute;
    width: 100%;
    padding-top: 96px;
    padding-bottom: 64px;
    background-color: #fff;
}

.sec-3-heading {
    letter-spacing: -0.56px !important;
}

.sec-3-microsoft-image {
    width: 252px;
    height: 102px;
}

.sec-4 {
    padding-top: 120px;
    padding-bottom: 240px;
    background-image: url("images/sec-4-bg.png");
    background-position: left bottom;
    background-size: 761px 507px;
    background-repeat: no-repeat;
}

.sec-4-desc {
    margin-top: 36px;
    color: #1F2937;
    font-family: Inter;
    align-self: stretch;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
}

.sec-4-card {
    color: var(--neutral-800, #111827);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.2px;
}

.pt-36 {
    padding-top: 36px;
}

.pb-36 {
    padding-bottom: 36px;
}

.sec-5 {
    padding-top: 120px;
    padding-bottom: 96px;
}

.sec-5-calls {
    margin-top: 72px;
}

.sec-5-call-text {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.sec-5-tele-number {
    font-weight: 600;
    cursor: pointer;
}

.sec-5-divider {
    height: 1px;
    background-color: var(--neutral-200, #EEEEF2);
}

.mt-32 {
    margin-top: 32px;
}

.mb-32 {
    margin-bottom: 32px;
}

.sec-6 {
    background-image: url(images/sec-6-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    // height: 601.4px;
}

.sec-6-img {
    height: 300px;
    width: 100%;
}

.sec-6-contents {
    // margin-top: 251px;
    // padding-top: 371px;
    // background-color: #fff;
    padding-top: 120px;
    background: var(--neutral-100, #FAFAFC);
}


.banner-img {
    min-width: 230px;
    max-height: 45px;
    padding: 0 96px;
    object-fit: contain;
}

.banner-img:last-of-type {
    padding-left: 0;
}

.inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 5rem;
}

.wrapper {
    position: absolute;
    display: flex;
}

.banner-sec {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
}
.banner-sec-front {
    display: flex;
    animation: swipe-front var(--speed) linear infinite backwards;
}

@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}
@keyframes swipe-front {
    0% {
        transform: translate(-100%);
    }

    100% {
        transform: translate(-0%);
    }
}

.sec-7 {
    padding-top: 130px;
    padding-bottom: 120px;
}

.sec-8 {
    padding-top: 120px;
    padding-bottom: 96px;
}

.sec-8-card-header {
    color: var(--neutral-700, #1F2937);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-top: 24px;
    letter-spacing: -0.2px;
}

.sec-8-card-desc {
    color: var(--neutral-500, #6B7280);
    margin-top: 12px;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    margin-top: 12px;
}

.sec-8-cards {
    margin-top: 32px;
}

.sec-8-ind-card {
    margin-top: 64px;
    padding-left: 10%;
    padding-right: 10%;
}

.sec-10 {
    padding-top: 96px;
    padding-bottom: 48px;
    background-image: url("images/sec-10-bg.png");
    background-color: var(--neutral-100, #FAFAFC);
    background-position: left bottom;
    background-size: 693px 462px;
    background-repeat: no-repeat;
    // background: ;
}

.sec-10::before {
    background-color: var(--neutral-100, red)
}

.sec-10-sub-heading {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.sec-10-heading {
    margin-top: 24px;
}

.sec-10-number {
    font-family: Inter;
    font-size: 72px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 79.2px */
    letter-spacing: -1.44px;
    background: linear-gradient(97deg, #00A3FF 8.69%, #008EFF 87.72%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    -webkit-font-smoothing: antialiased;
}

.sec-10-number-text {
    margin-left: 12px;
}

.sec-10-num-desc {
    margin-top: 12px;
    color: var(--neutral-600, #4B5563);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
}

.sec-10-number-card {
    padding-bottom: 48px;
}

.sec-11 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.sec-11-card {
    margin-top: 72px;
}

.sec-11-card-image {
    border-radius: 12px;
    // height: 300px;
    width: 100%;
    align-self: stretch;
}

.sec-11-card-details {
    margin-top: 40px;
    color: var(--neutral-400, #9CA3AF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.sec-11-card-desc {
    margin-top: 24px;
}

.sec-11-card-more {
    padding: 0px !important;
    margin-top: 16px;
    background: transparent;
}

.sec-12 {
    padding-top: 160px;
    padding-bottom: 102px;
    background-image: url(images/sec-12-bg.webp);
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
}

.sec-12-sub-heading {
    color: var(--ui-primary-400, #4592F7);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.sec-12-heading {
    margin-top: 24px;
}

.sec-12-desc {
    color: var(--neutral-200, #EEEEF2);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    margin-top: 32px;
}

.sec-11-contact-card {
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 5px 15px -3px rgba(0, 37, 112, 0.10);
    padding: 36px;
}

.mir-input {
    margin-top: 8px;
    margin-bottom: 24px;
}

.sec-12-form-note {
    color: var(--neutral-400, #9CA3AF);
    margin-bottom: 24px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

.form-submit {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-top: 16px;
}

.mir-btn-md-tertiary {
    width: auto;
}

.our-business-divider {
    background-color: #D6DBE0;
    height: 2px;
}

.sm-call-block {
    background: var(--ui-primary-500, #1D68F0);
    padding: 36px;
    flex-direction: column;
    align-items: center;
    gap: 64px;
}

.sm-call-content {
    color: var(--neutral-200, #EEEEF2);

    /* Paragraph/Medium/Medium */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    /* 30.6px */
}

.call-sm-divider {
    height: 1px;
    background-color: #fff;
    margin-top: 32px;
    margin-bottom: 32px;
    opacity: 0.2;
}


@media only screen and (max-width: 1201px) and (min-width: 1500px) {

    .circle-contents-1 {
        top: 14%;
        left: -5%;
    }

    .c-comp-1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .circle-contents-2 {
        left: 69% !important;
        top: 0px !important;
    }


    .circle-contents-3 {
        top: 60% !important;
        left: -3% !important;
    }

    .circle-contents-4 {
        top: -22% !important;
        left: 29% !important;
    }

    .circle-contents-5 {
        top: 126% !important;
        left: -33% !important;
    }

    .circle-contents-7 {
        top: 55% !important;
        left: 80% !important;
    }

    .circle-contents-6 {
        top: 121% !important;
        left: 91% !important;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 1100px) {

    .circle-contents-1 {
        top: 14%;
        left: -23%;
    }

    .c-comp-1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .circle-contents-2 {
        left: 89% !important;
        top: 16% !important;
    }

    .circle-contents-3 {
        top: 93% !important;
        left: 0% !important;
    }

    .circle-contents-4 {
        top: -22% !important;
        left: 29% !important;
    }

    .circle-contents-5 {
        top: 62% !important;
        left: -51% !important;
    }

    .circle-contents-7 {
        top: 55% !important;
        left: 69% !important;
    }

    .circle-contents-6 {
        top: 100% !important;
        left: 80% !important;
    }
}


@media only screen and (max-width: 1100px) and (min-width: 993px) {
    .circle-contents-1 {
        top: 14%;
        left: -17%;
    }

    .c-comp-1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .circle-contents-2 {
        left: 69% !important;
        top: 0px !important;
    }

    .circle-contents-3 {
        top: 80% !important;
        left: 0% !important;
    }

    .circle-contents-4 {
        top: -22% !important;
        left: 29% !important;
    }

    .circle-contents-5 {
        top: 60% !important;
        left: -48% !important;
    }

    .circle-contents-7 {
        top: 80% !important;
        left: 68% !important;
    }

    .circle-contents-6 {
        top: 51% !important;
        left: 90% !important;
    }
}

@media only screen and (max-width: 992px) and (min-width: 900px) {
    .circle-contents-1 {
        top: 14%;
        left: -13%;
    }

    .c-comp-1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .circle-contents-2 {
        left: 69% !important;
        top: 0px !important;
    }

    .circle-contents-3 {
        top: 80% !important;
        left: -3% !important;
    }

    .circle-contents-4 {
        top: -22% !important;
        left: 29% !important;
    }

    .circle-contents-5 {
        top: 60% !important;
        left: -43% !important;
    }

    .circle-contents-7 {
        top: 80% !important;
        left: 75% !important;
    }

    .circle-contents-6 {
        top: 51% !important;
        left: 90% !important;
    }
}

@media only screen and (max-width: 899px) and (min-width: 800px) {

    // .home-sec-2-main {
    //     background-size: 100% 84%;
    // }
    .circle-nested-circle {
        height: 70.18px;
        width: 70.18px;
        padding: 15.039px;
    }

    .circle-content-desc {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        text-align: center !important;
    }

    .circle-content-desc-1 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
    }

    .circle-content-desc-2 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        text-align: center;
    }

    .circle-content-desc-3 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        margin-top: 0px;
    }

    .circle-content-desc-4 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        margin-bottom: 0 !important;
    }

    .circle-content-desc-5 {
        margin-top: 5px;
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
    }

    .circle-content-desc-6 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        margin-top: 10px;
    }

    .circle-content-desc-7 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        margin-top: 12px;
    }

    .circle-contents-1 {
        top: 0%;
        left: -14%;
    }

    .c-comp-1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .circle-contents-1 {
        left: -9% !important;
        top: 7% !important;
    }

    .circle-contents-2 {
        left: 85% !important;
        top: 10% !important;
    }

    .circle-contents-3 {
        top: 80% !important;
        left: 1% !important;
    }

    .circle-contents-4 {
        top: -10% !important;
        left: 28% !important;
    }

    .circle-contents-5 {
        top: 50% !important;
        left: -36% !important;
    }

    .circle-contents-7 {
        top: 82% !important;
        left: 70% !important;
    }

    .circle-contents-6 {
        top: 28% !important;
        left: 88% !important;
    }
}

@media only screen and (max-width: 800px) and (min-width: 700px) {
    .circle-nested-circle {
        height: 70.18px;
        width: 70.18px;
        padding: 15.039px;
    }

    .circle-content-desc {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        text-align: center !important;
    }

    .circle-content-desc-1 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
    }

    .circle-content-desc-2 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        text-align: center;
    }

    .circle-content-desc-3 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        margin-top: 0px;
    }

    .circle-content-desc-4 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        margin-bottom: 0 !important;
    }

    .circle-content-desc-5 {
        margin-top: 5px;
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
    }

    .circle-content-desc-6 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        margin-top: 10px;
    }

    .circle-content-desc-7 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        margin-top: 12px;
    }

    .circle-contents-1 {
        top: 6% !important;
        left: -5% !important;
    }

    .c-comp-1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .circle-contents-2 {
        left: 69% !important;
        top: 0px !important;
    }

    .circle-contents-3 {
        top: 80% !important;
        left: -2% !important;
    }

    .circle-contents-4 {
        top: -22% !important;
        left: 29% !important;
    }

    .circle-contents-5 {
        top: 60% !important;
        left: -43% !important;
    }

    .circle-contents-7 {
        top: 77% !important;
        left: 75% !important;
    }

    .circle-contents-6 {
        top: 51% !important;
        left: 86% !important;
    }
}

@media only screen and (max-width: 768px) {

    .circle-1::after {
        width: 100% !important;
        height: auto !important;
    }

    .home-sec-2-main {
        .container {
            padding-left: auto !important;
            padding-right: auto !important;
            max-width: 85% !important;
        }
    }

    .circle-1 {
        // padding: calc(100% - 448.27px);
    }

    .circle-2 {
        margin: 15% !important;
        width: 70% !important;
        height: 70% !important;
        // width: 386.262px;
        // height: 386.262px;
        flex-shrink: 0;
    }

    .circle-nested-circle {
        height: 70.18px;
        width: 70.18px;
        padding: 15.039px;
    }

    .circle-content-desc {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        text-align: center !important;
    }

    .circle-content-desc-1 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
    }

    .circle-content-desc-2 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        text-align: left;
    }

    .circle-content-desc-3 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        margin-top: 0px;
    }

    .circle-content-desc-4 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        margin-bottom: 0 !important;
    }

    .circle-content-desc-5 {
        margin-top: 5px;
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
    }

    .circle-content-desc-6 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        margin-top: 10px;
    }

    .circle-content-desc-7 {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 160% !important;
        margin-top: 12px;
    }

    // .circle-contents-1 {
    //     top: 14%;
    //     left: -5%;
    // }

    // .c-comp-1 {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }

    // .circle-contents-2 {
    //     left: 69% !important;
    //     top: 0px !important;
    // }

    // .circle-contents-3 {
    //     top: 60% !important;
    //     left: -3% !important;
    // }

    // .circle-contents-4 {
    //     top: -22% !important;
    //     left: 29% !important;
    // }

    // .circle-contents-5 {
    //     top: 126% !important;
    //     left: -33% !important;
    // }

    // .circle-contents-7 {
    //     top: 55% !important;
    //     left: 80% !important;
    // }

    // .circle-contents-6 {
    //     top: 121% !important;
    //     left: 91% !important;
    // }

    .circle-center-heading {
        font-size: 24px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 140% !important;
        letter-spacing: -0.48px !important;
        margin-top: 50px;
    }

    .btn-ms-365-main {
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 170% !important;
        padding: 12px 28px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .sec-2-heading {
        font-size: 24px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 140% !important;
        letter-spacing: -0.48px !important;
    }

    .home-sec-2-main {
        padding-top: 206px !important;
    }

    .sec-3-heading {
        font-size: 28px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 130% !important;
        letter-spacing: -0.56px !important;
        text-align: center !important;
    }

    .sec-3-microsoft-image {
        margin-top: 24px;
    }

    .sec-4-heading {
        font-size: 40px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 120% !important;
        letter-spacing: -0.8px !important;
    }

    .sec-4-desc {
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 170% !important;
    }

    .sec-4-cards {
        margin-top: 64px !important;
    }

    .sec-4-card {
        font-family: Inter !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 170% !important;
    }

    .sec-4 {
        background: none !important;
        padding-bottom: 0 !important;
    }

    .sec-4-bg {
        height: 507px;
    }

    .sec-5-heading {
        font-size: 28px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 130% !important;
        letter-spacing: -0.56px !important;
    }

    .sec-5-heading {
        font-size: 28px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 130% !important;
        letter-spacing: -0.56px !important;
    }

    .sec-5-call-text {
        font-family: Inter !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 170% !important;
    }

    .sec-5-tele-number {
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 170% !important;
    }
}

@media screen and (max-width: 450px) {
    .sec-4-bg {
        height: 100%;
    }

    .mir-heading-l8-l2 {
        font-size: 40px !important;
    }

    .home-sec-1-main {
        height: 561px !important;
    }

    .home-sec-1 {
        padding-top: 64px;
    }

    .sec-1-desc-1 {
        font-size: 18px !important;
    }

    .btn-ms-365-main {
        border-radius: 73px !important;
        border: 1px solid rgba(32, 80, 184, 0.00) !important;
        background: linear-gradient(178deg, #000F35 1.53%, #03174A 115.73%) !important;
    }

    .circle-content-desc {
        margin-top: 0 !important;
    }

    .circle-content-desc-5 {
        padding: 0 !important;
    }

    .circle-content-desc-4 {
        padding: 0 !important;
    }

    .circle-content-desc-3 {
        padding: 0 !important;
    }

    .circle-content-desc-2 {
        padding: 0 !important;
        text-align: left;
    }

    .circle-content-desc-6 {
        padding: 0 !important;
    }

    .circle-content-desc-7 {
        padding: 0 !important;
    }

    .circle-content-desc-2 {
        text-align: left !important;
    }

    .sec-3-heading {
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 140% !important;
        letter-spacing: -0.18px !important;
        text-align: center !important;
    }

    .sec-3-microsoft-image {
        width: 126px;
        height: 51px;
    }

    .sec-3 {
        padding-top: 64px !important;
        padding-bottom: 32px !important;
    }

    .sec-4 {
        padding-top: 32px !important;
        padding-bottom: 32px !important;
    }

    .sec-4-heading {
        font-size: 28px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 130% !important;
        letter-spacing: -0.56px !important;
    }

    .sec-4-bg {
        height: 100%;
        width: 100%;
    }

    .sec-5 {
        padding-top: 52px;
        padding-bottom: 52px;
    }

    .sec-5-heading {
        font-size: 20px !important;
        font-weight: 600 !important;
        line-height: 140% !important;
        letter-spacing: -0.4px !important;
    }

    .sec-5-tele-number {
        text-align: left !important;
        margin-top: 24px !important;
    }

    .sm-visible {
        display: block !important;
        margin-top: 8px;
    }

    .sec-6-contents {
        padding-top: 0 !important;
    }

    .sec-6-img {
        height: 150px;
        width: 100%;
    }

    .mir-heading-l3-l2 {
        font-size: 20px !important;
        font-weight: 600 !important;
        line-height: 140% !important;
        letter-spacing: -0.4px !important;
    }

    .sec-7 {
        padding-top: 64px !important;
        padding-bottom: 52px !important;
    }

    .sec-8 {
        padding-top: 64px !important;
        padding-bottom: 64px !important;
    }

    .mir-heading-l6-l2 {
        font-size: 28px !important;
        font-weight: 600 !important;
        line-height: 130% !important;
        letter-spacing: -0.56px !important;
    }

    .sec-8-ind-card {
        text-align: center !important;
    }

    .mir-heading-l7-l2 {
        font-size: 28px !important;
        font-weight: 600 !important;
        line-height: 130% !important;
        letter-spacing: -0.56px !important;
        margin-bottom: 64px;
    }

    .sec-10-number-text {
        margin-left: 0 !important;
    }

    .sec-10 {
        padding-top: 62px !important;
        padding-bottom: 24px !important;
        background-image: none !important;
    }

    .sec-11 {
        padding-top: 64px !important;
        padding-bottom: 64px !important;
    }

    .view-all-btn {
        text-align: left;
        margin-top: 32px !important;
    }

    .sec-12 {
        padding-top: 120px;
        padding-bottom: 160px;
    }

    .sec-11-contact-card {
        margin-top: 64px !important;
    }

}

.home-sec-2-main-sm {
    padding-top: 167px;
    padding-bottom: 127px;
    background: linear-gradient(#010722, #000B2C, #030712);

    background:
        linear-gradient(to bottom, #010722, #000B2C, #030712, rgba(255, 0, 0, 0)),
        url('images/top-section_up.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.sm-impl-header {
    margin-top: 167px !important;
}

.sm-impl-btn {
    margin-top: 30px;
    margin-bottom: 23px;
}

.sm-circle-contents {
    margin-top: 29px;
    margin-bottom: 29px;
}

.modal-header-custom {
    color: var(--neutral-800, #111827);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.48px;
    margin-bottom: 24px;
    margin-top: 24px;
}

.region-group {
    border-bottom: 1px solid #EEEEF2;
    padding-top: 28px;
    padding-bottom: 28px;
}

.modal-header {
    border: none !important;
    padding-bottom: 0 !important;
}

.modal-body {
    padding-left: 56px !important;
    padding-right: 56px !important;
    padding-bottom: 56px !important;
}

.round-images {
    width: 100%;
}