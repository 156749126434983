.cloud-solutions.light-contact-form{
    background-color: '#FAFAFC';
    background-image: url(../../../../../../public/supply-chain/contact-form-bg.svg);
    .sec-12-heading{
        color: #111827;
    }
    .sec-12-desc{
        color: #111827;
    }
    .sec-11-contact-card{
        background: #FFFFFF;
    }

    .g-recaptcha {
        transform:scale(0.77);
        transform-origin:0 0;
    }
}

.g-recaptcha {
    position: relative;
    width: 100%;
    background: #f9f9f9;
    overflow: hidden;
}

.g-recaptcha > * {
    float: right;
    right: 0;
    margin: -2px -2px -10px;/*remove borders*/ 
}

.g-recaptcha::after{
    display: block;
    content: "";
    position: absolute;
    left:0;
    right:150px;
    top: 0;
    bottom:0;
    background-color: #f9f9f9;
    clear: both;
}