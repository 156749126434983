
.cloud-solutions{
    .banner-wrapper{
        margin-top: 92px;
        padding: 52px 64px 52px 64px;
        background-color: #07144e !important;
        background-image: url(../../../../../../public/supply-chain/blue-banner-bg.png);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: cover;
        border-radius: 12px;
        color: white;
        margin-bottom: 3.35rem;
        .left{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
        .right{
            width: 100%;
            height: 100%;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: end;
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .cloud-solutions{
        .banner-wrapper{
            border-radius: 0px;
            padding: 36px 36px 36px 36px;
            background-image: url(../../../../../../public//supply-chain/blue-banner-bg.png);
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: 200%;
            background-position-y: 100%;
            .right{
                margin-top: 1rem;
                justify-content: start;
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .cloud-solutions{
        .banner-wrapper{
            border-radius: 0px;
            padding: 36px 36px 36px 36px;
            background-image: url(../../../../../../public//supply-chain/blue-banner-bg.png);
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: 200%;
            background-position-y: 100%;
            .right{
                margin-top: 1rem;
                justify-content: start;
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .cloud-solutions{
        .banner-wrapper{
            border-radius: 12px;
            padding-top: 3.35rem;
            padding-bottom: 3.35rem;
            background-image: url(../../../../../../public//supply-chain/blue-banner-bg.png);
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 100%;
            .right{
                margin-top: 1rem;
                justify-content: start;
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .cloud-solutions{
        .banner-wrapper{
            padding-top: 3.35rem;
            padding-bottom: 3.35rem;
            border-radius: 12px;
            .right{
                margin-top: 0rem;
                justify-content: end;
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .cloud-solutions{
        .banner-wrapper{
            border-radius: 12px;
            padding-top: 3.35rem;
            padding-bottom: 3.35rem;
            .right{
                margin-top: 0rem;
                justify-content: end;
            }
        }
    }
}