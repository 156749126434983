.cloud-solutions.supply-benefits-wrapper{
    padding-top: 120px;
    padding-bottom: 96px;
    .header-content{
        text-align: center;
        small{
            display: block;
            font-weight: 600;
            text-transform: uppercase;
            color: #1D68F0;
            margin-bottom: 1.5rem;
        }
        h3{
            color: #111827;
            margin-bottom: 2rem;
        }
        p{
            max-width: 1000px;
            margin: 0 auto;
            margin-bottom: 4rem;
            color: #1F2937;
        }
    }
    .benefit-item{
        margin-top: 2rem;
        svg{
            margin-bottom: 1.4rem;
        }
        h4{
            font-weight: 600;
            max-width: 75%;
        }
        p{
            width: 100%;
            color: #4B5563;
            font-weight: 400;
            // max-width: 350px;
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .cloud-solutions.supply-benefits-wrapper{
        .header-content{
            h3{
                font-family: Inter;
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%; /* 41.6px */
                letter-spacing: -0.64px;
            }
        }
    }
    .benefit-item-wrapper{
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .benefit-item-wrapper{
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .benefit-item-wrapper{
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
    .benefit-item-wrapper:nth-child(1),
    .benefit-item-wrapper:nth-child(4){
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .benefit-item-wrapper:nth-child(0),
    .benefit-item-wrapper:nth-child(3){
        padding-right: 24px !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .benefit-item-wrapper{
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
    .benefit-item-wrapper:nth-child(1),
    .benefit-item-wrapper:nth-child(4){
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .benefit-item-wrapper:nth-child(0),
    .benefit-item-wrapper:nth-child(3){
        padding-right: 24px !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
}