.partner-card {
    // border-bottom: 2px solid transparent;
    border-top: 1px solid;
    border-image: linear-gradient(90deg, rgba(93, 127, 168, 0) 0%, #5D7FA8 47%, rgba(93, 127, 168, 0) 100%);
    border-image-slice: 1;
}

@media only screen and (max-width: 600px) {
    .partner-details {
        margin-top: 7% !important;
    }
}