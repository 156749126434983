@import '~mir-global-style';

.privacy-heading-bg {
    background: var(--neutral-100, #FAFAFC);
    padding-top: 72px;
    padding-bottom: 72px;
}

.privacy-last-updated {
    margin-top: 24px;
}

.privacy-details {
    padding-top: 72px;
    padding-bottom: 120px;

    ul {
        padding-left: 2rem !important;

        li {
            @extend .text-secondary-700;
            margin-top: 16px;
            font-weight: 400;
            line-height: 170%;
        }
    }

    b {
        font-weight: 600 !important;
    }

    p {
        @extend .text-secondary-700;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
    }

    h2 {
        letter-spacing: -0.48px;
        @extend .mir-heading-l2-l2;
        margin-top: 48px;
        margin-bottom: 24px;
    }

    .sub {
        font-size: 18px;
        font-weight: 600;
    }
}

.privacy-table {
    margin-bottom: 36px;
    color: var(--neutral-700, #1F2937);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    line-height: 170%;
    width: 100%;
    border-collapse: collapse;

    tr {

        td:nth-child(1) {
            background: var(--neutral-100, #FAFAFC);
            font-weight: 500;
            width: 25%;
        }

        td:nth-child(3) {
            width: 10%;
            text-align: center;
        }

        td {
            border: 1px solid var(--neutral-300, #D6DBE0);
            padding: 24px;
        }
    }
}