.section-headings{
    background-color: #030712;
    // margin-bottom: 1rem;
    position: sticky;
    top: 0;
    bottom: 0;
    z-index: 100;
    .items{
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow-x: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        a{
            padding: 1.4rem 0;
            border-bottom: solid;
            border-width: 4px;
            border-color: transparent;
            color: #9CA3AF;
            white-space: nowrap;
            margin: 0 1rem;
        }
        a::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
            opacity: 0;
        }
        a.active{
            color: #fff;
            border-color: #1D68F0;
        }
        a:hover{
            transition: all .3s ease;
            color: #fff;
            border-color: #1D68F0;
        }
    }
}   

.section-headings.items{
    -ms-overflow-style: none;
}
.section-headings.items::-webkit-scrollbar{
    display: none;
    width: 0 !important
}