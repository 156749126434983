.blogs-preview-section {
    padding-top: 8px;
    padding-bottom: 120px;
}

.preview-card {
    margin-top: 64px;
    cursor: pointer;

    &:hover {
        .card-header {
            color: #003C71;
        }

        .img-container {
            img {
                transform: scale(1.1);
                transform-origin: 50% 50%;
            }
        }
    }
}

.posted-date {
    margin-top: 32px;
    line-height: 150% !important;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.img-container {
    display: inline-block;
    overflow: hidden;
    border-radius: 12px;
}

.img-container img {
    display: block;
    transition: transform .4s;
}

.preview-image {
    height: 200px;
    width: 100%;
    border-radius: 12px;
}

.meeting-section {
    margin-top: 64px !important;
    padding-left: 62px;
    padding-right: 62px;
    padding-top: 36px;
}

.meeting-text {
    margin-top: 12px;
}

.svg-container {
    margin-top: 42px;
    margin-bottom: 42px;
}


.blog-header-div {
    background: url('../../../../public/blogs/list-banner.png');
    padding-top: 73px;
    padding-bottom: 96px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.blog-header-div-para {
    margin-top: 16px;
}