.modal-header-custom {
    color: var(--neutral-800, #111827);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.48px;
    margin-bottom: 24px;
    margin-top: 24px;
}

.region-group {
    border-bottom: 1px solid #EEEEF2;
    padding-top: 28px;
    padding-bottom: 28px;
}

.modal-header {
    border: none !important;
    padding-bottom: 0 !important;
}

.modal-body {
    padding-left: 56px !important;
    padding-right: 56px !important;
    padding-bottom: 56px !important;
}