/** 
***
***
***
BUTTON STYLES
***
***
***
**/

/* button sizes */
.mir-btn-lg{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: all .3s ease;
    outline: none;
    border: none;
    padding: 16px 24px;
}
.mir-btn-md{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: all .3s ease;
    outline: none;
    border: none;
    padding: 12px 20px;
}
.mir-btn-sm{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: all .3s ease;
    outline: none;
    border: none;
    padding: 8px 16px;
}

/* primary buttons */
.mir-btn-primary{
    background: #1D68F0;
    color: #fff;
}
.mir-btn-primary:hover{
    background: #004BD3;
}
.mir-btn-primary:focus{
    background: #00319C;
}
.mir-btn-primary:disabled{
    background: #D6DBE0;
    color: #9CA3AF;
}

/* secondary buttons */
.mir-btn-secondary{
    background: #fff;
    color: #1D68F0;
}
.mir-btn-secondary:hover{
    background: #030712;
    color: #fff;
}
.mir-btn-secondary:focus{
    background: #030712;
    color: #fff;
}
.mir-btn-secondary:disabled{
    background: #D6DBE0;
    color: #9CA3AF;
}

/* tertiary buttons */
.mir-btn-tertiary{
    background: #030712;
    color: #fff;
}
.mir-btn-tertiary:hover{
    background: #1D68F0;
    color: #fff;
}
.mir-btn-tertiary:focus{
    background: #00319C;
    color: #fff;
}
.mir-btn-tertiary:disabled{
    background: #D6DBE0;
    color: #9CA3AF;
}

/* outlined buttons */
.mir-btn-outline{
    background: #fff;
    color: #1D68F0;
    border: 1px solid #1D68F0;
}
.mir-btn-outline:hover{
    background: #1D68F0;
    color: #fff;
}
.mir-btn-outline:focus{
    background: #00319C;
    color: #fff;
}
.mir-btn-outline:disabled{
    background: #D6DBE0;
    color: #9CA3AF;
}

/* transparent buttons */
.mir-btn-transparent{
    background: transparent;
    color: #1D68F0;;
}
.mir-btn-transparent:hover{
    background: transparent;
    color: #1D68F0;
}
.mir-btn-transparent:focus{
    background: transparent;
    color: #00319C;
}
.mir-btn-transparent:disabled{
    background: transparent;
    color: #9CA3AF;
}