.cloud-solutions.success-story-wrapper{
    background-color: #EEF3FD !important;
    background-repeat: no-repeat !important;
    background-position: bottom right !important;
    background-size: contain !important;
    // padding: 4rem 0;
    p.subtitle{
        color: #1D68F0;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
        font-weight: 600;
        font-size: 12px;
    }
    h2{
        margin-bottom: 1.5rem;
        // max-width: 500px;
    }
    .content p{
        // max-width: 80%;
        line-height: 30.6px;
        font-size: 18px;
        margin-bottom: 3rem;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .cloud-solutions.success-story-wrapper{
        padding-top: 64px;
        .right-content{
            min-height: 350px;
            max-height: 350px;
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .cloud-solutions.success-story-wrapper{
        padding-top: 64px;
        .right-content{
            min-height: 350px;
            max-height: 350px;
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .cloud-solutions.success-story-wrapper{
        padding-top: 120px;
        .right-content{
            min-height: 1000px;
            max-height: 1000px;
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .cloud-solutions.success-story-wrapper{
        padding-top: 80px;
        .right-content{
            min-height: 500px;
            max-height: 500px;
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .cloud-solutions.success-story-wrapper{
        padding-top: 80px;
        .right-content{
            min-height: 500px;
            max-height: 500px;
        }
    }
}