.cloud-solutions.efficiency{
    padding-top: 5rem;
    background: #fff;
    .left-content{
        .heading{
            max-width: 85%;
            background: none;
            padding: 0;
            padding-bottom: 1rem;
        }
        .subtitle{
            max-width: 500px;
        }
    }
}