.cloud-solutions.how-help{
    // background: url(../../../../../../public/supply-chain/help-bg.png);
    background-repeat: no-repeat !important;
    background-position: bottom right !important; 
    background-size: contain !important;
    h4.subtitle{
        color: #1D68F0;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
        font-weight: 600;
    }
    h2{
        margin-bottom: 1.5rem;
        max-width: 500px;
    }
    .content p{
        max-width: 80%;
    }
}

.cloud-solutions.how-help{
    padding: 4rem 0;
    padding-top: 5rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .cloud-solutions.how-help{
        background-size: 100% !important;
        padding-top: 2rem;
        h2{
            margin-bottom: 1.5rem;
            max-width: 100%;
        }
        .content p{
            max-width: 100%;
        }
        .right-side{
            min-height: 350px;
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .cloud-solutions.how-help{
        background-size: 100% !important;
        h2{
            margin-bottom: 1.5rem;
            max-width: 100%;
        }
        .content p{
            max-width: 100%;
        }
        .right-side{
            min-height: 500px;
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .cloud-solutions.how-help{
        background-size: 50% !important;
        .content p{
            max-width: 100%;
        }
        .right-side{
            min-height: 500px;
        }
    }
    .cloud-solutions.how-help{
        h2{
            margin-bottom: 1.5rem;
            max-width: 100%;
        }
        .content p{
            max-width: 100%;
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .cloud-solutions.how-help{
        background-size: 40% !important;
        background-position-y: 72% !important;
        .content p{
            max-width: 100%;
        }
        .right-side{
            min-height: 400px;
            max-height: 300px;
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .cloud-solutions.how-help{
        background-size: 40% !important;
        background-position-y: 72% !important;
        .content p{
            max-width: 100%;
        }
        .right-side{
            min-height: 400px;
            max-height: 300px;
        }
    }
}