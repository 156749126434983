

.process-slider-wrapper{
    .slider-wrapper{
        position: relative;
        overflow-x: visible;
        padding-bottom: 96px;
        .customer-slide-item-wrapper{
            // padding: 0 1rem;
            height: 100%;
            display: flex;
            align-items: center;
            .customer-slide-item{
                width: 100%;
                border-radius: 16px;
                background: #06206E;
                background-size: cover;
                background-repeat: no-repeat;
                padding: 64px;
                img{
                    margin-bottom: 5rem;
                }
                .message{
                    color: #EBEBF2;
                    margin-bottom: 2rem;
                    p{
                        margin-bottom: 1rem;
                        letter-spacing: -0.25px;
                    }
                }
                .person{
                    padding-top: 1.5rem;
                    h4{
                        color: #A6B5D8;
                        font-weight: 600;
                    }
                    h6{
                        margin-top: -0.2rem;
                        color: #A6B5D8;
                    }
                }
            }
        }
    
        .slick-list{
            overflow: visible;
        }
    
        .slick-track{
            display: flex;
            align-items: center;
            .slick-slide, 
            .slick-cloned{
                opacity: 1;
                transform: scale(1);
                margin-right: 1.5rem;
            }
            .slick-cloned.slick-current, 
            .slick-slide.slick-current{
                opacity: 1;
                transform: scale(1);
                .process-slider-item{
                    background: #1D68F0;
                    .slide-item{
                        p{
                            color: #000;
                        }
                        h4{
                            color: #fff;
                        }
                        .gray-icon{
                            display: none;
                        }
                        .blue-icon{
                            display: block;
                        }
                    }
                }
            }
        }
    }
    
    .nav-btns{
        width: fit-content;
        .btn-wrapper{
            position: relative;
            display: flex;
            justify-content: end;
            .arrow-btn{
                border-radius: 100%;
                background: #EBEBF2;
                // box-shadow: 0px 2px 25px -5px #183CA733;
                width: 56px;
                height: 56px;
                padding: 12px;
                z-index: 99;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                    path{
                        fill: #9CA3AF;
                    }
                }
            }
            .arrow-btn:hover{
                transition: all .3s ease;
                background: #1D68F0;
                svg{
                    path{
                        fill: #fff;
                    }
                }
            }
            .arrow-btn-prev{
                margin-right: 0.8rem;
            }
        }
    }
    
    .process-slider-item{
        background: #ebebf2;
        border-radius: 8px;
        // padding: 32px;
        // height: 300px;
        overflow: hidden;
        .slide-item{
            display: flex;
            flex-flow: column;
            display: flex;
            justify-content: space-between;
            height: 100%;
            .blue-icon{
                display: none;
            }
            .gray-icon{
                display: block;
            }
            p{
                font-size: 12px;
                font-weight: 600;
                color: #6B7280;
                width: fit-content;
                border-radius: 110px;
                padding: 6px 14px 6px 14px;
                background: #FFFFFF;
            }
            h4{
                max-width: 300px;
                font-size: 18px;
                line-height: 28px;
                letter-spacing: -1%;
            }
            img{
                width: 64px;
                height: 64px;
                object-fit: contain;
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .main-heading{
        margin-bottom: 2rem;
    }
    .customer-slide-item{
        padding: 2rem !important;
    }
    .message{
        color: #EBEBF2;
        margin-bottom: 2rem;
        p{
            margin-bottom: 1rem;
            letter-spacing: -0.25px;
            font-size: 1.1rem;
            line-height: 1.7rem;
        }
    }    
    .process-slider-item{
        padding: 32px;
        height: 270px;
        max-width: 100%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .main-heading{
        margin-bottom: 2rem;
    }
    .customer-slide-item{
        padding: 2rem !important;
    }
    .message{
        color: #EBEBF2;
        margin-bottom: 2rem;
        p{
            margin-bottom: 1rem;
            letter-spacing: -0.25px;
            font-size: 1.1rem;
            line-height: 1.7rem;
        }
    }    
    .process-slider-item{
        padding: 32px;
        height: 270px;
        max-width: 100%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .main-heading{
        margin-bottom: 5rem;
    }
    .customer-slide-item{
        padding: 64px !important;
    }
    .message{
        color: #EBEBF2;
        margin-bottom: 2rem;
        p{
            margin-bottom: 1rem;
            letter-spacing: -0.25px;
        }
    }    
    .process-slider-item{
        padding: 32px;
        height: 270px;
        max-width: 270px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .main-heading{
        margin-bottom: 5rem;
    }
    .customer-slide-item{
        padding: 64px !important;
    }
    .message{
        color: #EBEBF2;
        margin-bottom: 2rem;
        p{
            margin-bottom: 1rem;
            letter-spacing: -0.25px;
        }
    }    
    .process-slider-item{
        padding: 32px;
        height: 270px;
        max-width: 270px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .main-heading{
        margin-bottom: 5rem;
    }
    .customer-slide-item{
        padding: 64px !important;
    }
    .message{
        color: #EBEBF2;
        margin-bottom: 2rem;
        p{
            margin-bottom: 1rem;
            letter-spacing: -0.25px;
        }
    }    
    .process-slider-item{
        padding: 32px;
        height: 270px;
        max-width: 270px;
    }
}