.blog-read-container {
    padding-top: 53px;
    padding-bottom: 120px;
}

.blog-content {
    margin-top: 48px;
    margin-bottom: 32px;
    color: #1F2937 !important;
}

.blog-image {
    margin-top: 52px;
    margin-bottom: 52px;
    width: 100%;
    border-radius: 8px;
}

.social-media {
    margin-top: 32px;
}

.divider {
    line-height: 150% !important;
    height: 1px;
    background-color: #D6DBE0;
}

.social-media-buttons {
    margin-right: 8px;
    cursor: pointer;

    &:hover {
        svg {
            path {
                fill: #1D68F0 !important;
            }
        }
    }
}

.schedule-meeting-section {
    padding: 48px;
    border-radius: 4px;
    background: url('../../../../public/blogs/schedule-meeting.png') no-repeat;
    background-color: var(--ui-primary-800, #001D6C);
    background-position: 110% 20%;
    background-repeat: no-repeat;
    background-size: 37%;
    margin-top: 64px;
}

.blog-title {
    margin-top: 36px;
    margin-bottom: 20px;
}


.mb-4 {
    margin-bottom: 16px !important;
}

.mir-paragraph-md-l4-l7-custom {
    color: var(--neutral-700, #1F2937);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
}

.blog-h4 {
    color: var(--neutral-900, #030712);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.18px;
}

.blog-quote {
    color: var(--neutral-700, #1F2937);
    font-family: Lora;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 170%;
}

.contactus-heading-blog {
    letter-spacing: -0.48px;
}

.blog-contact-btn {
    margin-top: 32px;
}

.blog-social-icon {
    height: 32px;
    width: 32px;
}