

.slider-wrapper{
    position: relative;
    overflow-x: visible;
    padding-bottom: 8rem;
    .customer-slide-item-wrapper{
        // padding: 0 1rem;
        height: 100%;
        display: flex;
        align-items: center;
        .customer-slide-item{
            width: 100%;
            border-radius: 16px;
            background: #06206E;
            background-image: url(../../../public/slider/slide-bg.webp);
            background-size: cover;
            background-repeat: no-repeat;
            padding: 64px;
            img{
                margin-bottom: 5rem;
            }
            .message{
                color: #EBEBF2;
                margin-bottom: 2rem;
                p{
                    margin-bottom: 1rem;
                    letter-spacing: -0.25px;
                }
            }
            .person{
                padding-top: 1.5rem;
                h4{
                    color: #A6B5D8;
                    font-weight: 600;
                }
                h6{
                    margin-top: -0.2rem;
                    color: #A6B5D8;
                }
            }
        }
    }
}

.arrow-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .container{
        // max-width: 900px;
        height: 100%;
        position: relative;
    }
    .btn-wrapper{
        height: 100%;
        position: relative;
        margin: 0 auto;
        .arrow-btn{
            border-radius: 100%;
            background: #fff;
            box-shadow: 0px 2px 25px -5px #183CA733;
            width: 60px;
            height: 60px;
            padding: 12px;
            z-index: 1000000;
            border: none;
        }
        .arrow-btn-prev{
            position: absolute;
            top: 37%;
        }
        .arrow-btn-next{
            position: absolute;
            right: 0;
            top: 37%;
        }
    }
}

.slick-track{
    display: flex;
    align-items: center;
    .slick-slide, 
    .slick-cloned{
        opacity: 0.5;
        transform: scale(0.8);
        transition: all .3s ease;
        .customer-slide-item-wrapper{
            .customer-slide-item{
                background: #9CA3AF;
            }
        }
    }
    .slick-cloned.slick-current, 
    .slick-slide.slick-current{
        opacity: 1;
        transform: scale(1);
        transition: all .3s ease;
        .customer-slide-item-wrapper{
            .customer-slide-item{
                background: #06206E;
                background-image: url(../../../public/slider/slide-bg.webp);
                background-size: cover;
                background-repeat: no-repeat;
                padding: 64px;
            }
        }
    }
}

.main-heading{
    margin: 2rem 0;
    margin-bottom: 5rem;
    text-align: center;
    color: #1F2937;
    font-weight: 600 !important;
    letter-spacing: -0.5px;
    span{
        color: #1D68F0;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .main-heading{
        margin-bottom: 2rem;
    }
    .customer-slide-item{
        padding: 2rem !important;
    }
    .message{
        color: #EBEBF2;
        margin-bottom: 2rem;
        p{
            margin-bottom: 1rem;
            letter-spacing: -0.25px;
            font-size: 1.1rem;
            line-height: 1.7rem;
        }
    }    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .main-heading{
        margin-bottom: 2rem;
    }
    .customer-slide-item{
        padding: 2rem !important;
    }
    .message{
        color: #EBEBF2;
        margin-bottom: 2rem;
        p{
            margin-bottom: 1rem;
            letter-spacing: -0.25px;
            font-size: 1.1rem;
            line-height: 1.7rem;
        }
    }    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .main-heading{
        margin-bottom: 5rem;
    }
    .customer-slide-item{
        padding: 64px !important;
    }
    .message{
        color: #EBEBF2;
        margin-bottom: 2rem;
        p{
            margin-bottom: 1rem;
            letter-spacing: -0.25px;
        }
    }    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .main-heading{
        margin-bottom: 5rem;
    }
    .customer-slide-item{
        padding: 64px !important;
    }
    .message{
        color: #EBEBF2;
        margin-bottom: 2rem;
        p{
            margin-bottom: 1rem;
            letter-spacing: -0.25px;
        }
    }    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .main-heading{
        margin-bottom: 5rem;
    }
    .customer-slide-item{
        padding: 64px !important;
    }
    .message{
        color: #EBEBF2;
        margin-bottom: 2rem;
        p{
            margin-bottom: 1rem;
            letter-spacing: -0.25px;
        }
    }    
}