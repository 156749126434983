@import url('https://fonts.cdnfonts.com/css/inter');

.nw-banner-chat-block {
    border-radius: 12px 12px 12px 0px;
    background: var(--primary-200, #D6E8FF);
    padding: 10px 16px;
    align-items: flex-start;
    gap: 10px;
    color: var(--primary-500, #2A69CE);
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.int-sec-4 {
    background: var(--primary-800, #010F3B);
    padding-top: 96px;
    padding-bottom: 96px;
}

.int-sec-4-content-header {
    width: 70%;
    color: var(--neutral-200, #EEEEF2);
    margin-top: 16px;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.48px;
}

.int-assess-top-content-container {
    /* height: 155vh; */
}


.wraper_int_assess {
    position: relative;
    z-index: 2;
    width: 100%;
    /* background: #0868d5; */
    position: relative;
    width: 100%;
    float: left;
    margin-top: 119px;
}

.wraper_int_assess_main {
    /* margin-top: 15vh; */
}

.int-assess-tool-font {
    color: #2A69CE;
    /* Paragraph/Overline/Large */
    /* font-family: Inter; */
    font-size: 14px;
    /* height: 2.40vw; */
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 16.8px */
    letter-spacing: 0.7px;
    border-radius: 12px 12px 12px 0px;
    background: #D6E8FF;
    text-align: center;
    height: 37px;
    /* padding-top:1.5%; */
    padding: 10px 16px;
    text-transform: uppercase;
}

.int-assess-tool-column {
    /* margin-bottom: 9vh; */
}

.int-assess-hire-content {
    color: var(--neutral-800, #111827);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.96px;
}

.int-assess-margin-bt-24 {
    margin-bottom: 24px;
}

.int-assess-subheader {
    color: var(--neutral-600, #4B5563);
    text-align: center;

    /* Paragraph/Large/Regular */
    /* font-family: Inter; */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 30px */
    /* padding: 3%; */
}

.int-assess-book-btn:hover {
    color: #FFFFFF;
}

.int-assess-book-btn {
    color: #FFF;
    /* Paragraph/Regular/Medium */
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 24px */
    border-radius: 6px;
    background: var(--primary-500, #2A69CE);
    padding: 12px 20px;
    align-items: center;
}

.int-assess-book-label {
    padding-right: 12px;
}

.int-assess-btn-book {
    /* width: 46vw; */
    margin-top: 40px;
}

.int-assess-image-1 {
    margin-top: 64px;
    /* z-index: 1; */
    /* position: relative; */
    /* top: 47%; */
    /* right: 13%; */
}

.int-assess-image-3 {
    z-index: 1;
    position: relative;
    bottom: 25%;
    left: 80%;
}

.int-assess-bid-header {
    color: var(--neutral-800, #111827);

    /* Heading/H2/Bold/Desktop */
    /* font-family: Inter; */
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 43.2px */
    letter-spacing: -0.72px;
}

.int-assess-bid-subheader {
    color: var(--neutral-600, #4B5563);

    /* Paragraph/Medium/Regular */
    /* font-family: Inter; */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
}

.int-assess-bid-right-header {
    color: var(--neutral-800, #111827);

    /* Heading/H6/Bold/Desktop */
    /* font-family: Inter; */
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 28px */
    letter-spacing: -0.2px;
}

.int-assess-bid-right-subheader {
    color: var(--neutral-600, #4B5563);

    /* Paragraph/Regular/Regular */
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
}

.int-sec-1 {
    padding-top: 150px;
    background: linear-gradient(179deg, #FBFBFD 87.14%, #A6CFFF 129.44%);
}

.int-sec-2 {
    padding-top: 96px;
    padding-bottom: 96px;
}

.int-sec-3 {
    padding-top: 96px;
    padding-bottom: 96px;
    background: var(--neutral-100, #FAFAFC);
}

.int-sec-3-content {
    margin-top: 40px;
    color: var(--primary-700, #06206E);
    text-align: center;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.56px;
}

.int-sec-3-content-2 {
    margin-top: 24px;
    color: var(--primary-500, #2A69CE);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.2px;
}

.int-sec-4-nums {
    color: var(--neutral-200, #EEEEF2);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.2px;
}

.int-sec-4-content-header-sub {
    margin-top: 4px;
    color: var(--primary-400, #408FF6);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.48px;
}

.int-sec-4-content-desc {
    margin-top: 24px;
    color: var(--neutral-200, #EEEEF2);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    opacity: 0.7;
}

.int-sec-5 {
    background-color: var(--primary-100, #F0F7FF);
    /* height: 500px; */
    background-image: url('../../../../public/interview-assisstance/scale.png');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 606px 100%;
    padding-top: 96px;
    padding-bottom: 96px;
}

.int-sec-5-tooltip {
    margin-left: 8px;
    color: var(--primary-500, #2A69CE);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.int-sec-5-header {
    margin-top: 24px;
    color: var(--neutral-800, #111827);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.48px;
}

.int-sec-5-desc {
    margin-top: 12px;
    color: var(--neutral-600, #4B5563);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.int-sec-5-btn {
    margin-top: 60px !important;
    border-radius: 6px;
    padding: 12px 20px;
    background: var(--primary-500, #2A69CE);
    border: none;
    color: var(--shades-white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.int-sec-9-btn {
    margin-top: 60px;
    border-radius: 6px;
    padding: 12px 20px;
    background: var(--primary-500, #2A69CE);
    border: none;
    color: var(--shades-white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.int-sec-7-btn {
    margin-top: 60px;
    border-radius: 6px;
    padding: 12px 20px;
    background: var(--primary-500, #2A69CE);
    border: none;
    color: var(--shades-white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.int-sec-6 {
    padding-top: 112px;
    padding-bottom: 112px;
    background: var(--shades-white, #FFF);
}

.int-sec-6-header {
    color: var(--neutral-800, #111827);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.72px;
}

.int-sec-6-desc {
    margin-top: 16px;
    color: var(--neutral-600, #4B5563);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.int-sec-6-timeline-img {
    border-radius: 4px;
    border: 1px solid var(--primary-200, #D6E8FF);
    background: var(--primary-100, #F0F7FF);
    padding: 12px;
    width: fit-content;
}

.int-sec-6-timeline-divider {
    margin-top: 16px;
    height: 85px;
    width: 1px;
    background: #8BBEF8;
}

.int-sec-6-card-header {
    color: var(--neutral-800, #111827);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.48px;
}

.int-sec-6-card-desc {
    margin-top: 10px;
    color: var(--neutral-600, #4B5563);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.int-sec-6-card {
    margin-bottom: 16px;
}

.int-sec-7 {
    padding-top: 32px;
    padding-bottom: 32px;
}

.int-sec-7-container {
    padding: 64px;
    border-radius: 12px;
    background: var(--primary-900, #000F26);
}

.int-sec-7-content {
    color: var(--shades-white, #FFF);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.56px;
}

// .int-sec-7 {
//     padding-top: 96px;
//     padding-bottom: 32px;
// }

.int-sec-7-header {
    margin-top: 24px;
    color: var(--neutral-800, #111827);
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.72px;
}

.int-sec-7-desc {
    margin-top: 24px;
    color: var(--neutral-600, #4B5563);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.int-sec-8 {
    padding-top: 64px;
    padding-bottom: 64px;
}

.int-sec-8-kf-1 {
    width: 48px;
    height: 48px;
}

.int-sec-8-kf-1-header {
    margin-top: 32px;
    color: var(--neutral-800, #111827);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.56px;
}

.int-sec-8-accordian-container {
    margin-top: 32px;
}

.accordion-item {
    border: none;
    box-shadow: none;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button {
    color: var(--neutral-800, #111827);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.2px;
    padding-bottom: 12px !important;
}

.accordion-button-temp {
    margin-bottom: 12px;
    color: var(--neutral-800, #111827);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.2px;
}



.accordion-button:not(.collapsed) {
    background-color: #ffffff;
    color: inherit;
    box-shadow: none !important;
    border: none !important;
    // border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-button {
    box-shadow: inset 0 1px 0 #D6DBE0;
    padding-top: 32px;
    padding-bottom: 32px;
}

.accordian-content {
    color: var(--neutral-600, #4B5563);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.int-sec-8-kf-images {
    width: 100%;
    background-color: #F0F7FF;
    border-radius: 12px;
}

.int-sec-8-accordian-list-item {
    margin-top: 12px;
}

.int-sec-9 {
    padding-top: 132px;
    padding-bottom: 132px;
    background: url('../../../../public/interview-assisstance/sec-9-bg.png'), lightgray;
    background-size: cover;
    background-repeat: no-repeat;
}

.int-sec-9-header {
    color: var(--neutral-200, #EEEEF2);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.96px;
}

.int-sec-9-header-start {
    color: var(--primary-400, #408FF6);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.96px;
}


@media only screen and (max-width: 600px) {
    .int-assess-tool-font {
        font-size: 12px;
    }

    .int-assess-hire-content {
        font-size: 36px;
    }

    .int-assess-subheader {
        font-size: 18px;
    }

    .int-assess-bid-header {
        font-size: 32px;
    }

    .int-assess-bid-subheader {
        font-size: 16px;
        margin-bottom: 52px;
    }

    .int-sec-3 {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    .int-sec-3-content {
        font-size: 24px;
    }

    .int-sec-3-content-2 {
        font-size: 18px;
    }

    .int-sec-4-header {
        font-size: 32px !important;
    }

    .int-sec-4-nums {
        font-size: 18px;
    }

    .int-sec-4-content-header {
        font-size: 20px;
    }

    .int-sec-5 {
        background-image: none;
    }

    .int-sec-5-hidden-img {
        margin-top: 32px;
    }

    .int-sec-5 {
        padding-top: 64px;
        padding-bottom: 0px;
    }

    .int-sec-5-tooltip {
        font-size: 12px;
    }

    .int-sec-5-desc {
        font-size: 16px;
    }

    .int-sec-6-header {
        font-size: 32px;
    }

    .int-sec-5-header {
        font-size: 20px;
    }

    .int-sec-6-desc {
        font-size: 16px;
        margin-bottom: 52px;
    }

    .int-sec-6-timeline-img {
        padding: 4px;
    }

    .int-sec-6-card-header {
        font-size: 20px;
    }

    .int-sec-7 {
        padding-top: 32px;
    }

    .int-sec-7-btn {
        width: 100%;
        margin-top: 52px !important;
    }

    .int-sec-7-header {
        font-size: 32px;
    }

    .int-sec-7-desc {
        font-size: 16px;
    }

    .int-sec-8-kf-1-header {
        font-size: 24px;
    }

    .accordion-button {
        font-size: 18px;
    }

    .accordion-button-temp {
        font-size: 18px;
    }

    .int-sec-8-kf-images-margin {
        margin-top: 64px;
    }

    .int-sec-9-header {
        font-size: 32px;
        line-height: 130%;
        letter-spacing: -0.64px;
    }

    .int-sec-9-header-start {
        font-size: 32px;
    }

    .int-sec-9-btn {
        text-align: center;
        width: 100%;
    }

    .nw-banner-chat-block {
        font-size: 12px !important;
    }
}

.int-assess-book-btn:hover {
    color: #FFFFFF;
}

.int-assess-book-btn {
    color: #FFF;
    /* Paragraph/Regular/Medium */
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 24px */
    border-radius: 6px;
    background: var(--primary-500, #2A69CE);
    padding: 12px 20px;
    align-items: center;
}

.int-sec-4-header {
    margin-top: 24px;
    color: var(--neutral-100, #F7F8FA);
    font-family: "Inter";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.96px;
    padding-bottom: 24px;
}

.nw-sec-4-contents {
    margin-top: 32px !important;
}



.accordion-button::after {

    // content: "" !important;
    // background-image: url('../../../../public/interview-assisstance/CaretUp.png') !important;
    // background-size: 24px 24px !important;
    // // display: inline-block;
    // width: 24px !important; 
    // height: 24px !important;
    // content:"" !important;


    // // background-image: none !important;
    // // font-family: "Inter" !important;
    // content: "" !important;
    // background-image: url('../../../../public/interview-assisstance/CaretUp.png') !important;
    // background-size: 24px 24px;
    // font-size: 18px !important;
    // // color: #fca301 !important;
    // line-height: 32px !important;
    // font-style: normal !important;
    // transition: all 0.3s ease-in-out !important;
    // -webkit-transition: all 0.3s ease-in-out !important;
}

.accordion-button::after {
    // flex-shrink: initial !important;
    // width: auto !important;
    // height: auto !important;
    // margin-left: auto !important;
}

// .accordion-button:not(.collapsed)::after {
//     content: "" !important;
//     background-image: url('../../../../public/interview-assisstance/CaretUp.png') !important;
//     background-size: 24px 24px !important;
//     // display: inline-block;
//     width: 24px !important; 
//     height: 24px !important;
//     content:"" !important;
// }

button.accordion-button.collapsed {
    position: relative !important;
    padding-top: 24px !important;
    padding-right: 0px !important;
    padding-bottom: 22px !important;
    cursor: pointer !important;
    padding-left: 0px !important;
    margin: 0 !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #1e1e1e !important;
    line-height: 27px !important;
    letter-spacing: -0.6px !important;
}

button.accordion-button {
    position: relative !important;
    padding-top: 24px !important;
    padding-right: 0px !important;
    padding-bottom: 22px !important;
    cursor: pointer !important;
    padding-left: 0px !important;
}

button.accordion-button {
    outline: none !important;
    border: 0px !important;
}

button.accordion-button:focus {
    outline: none !important;
    border: 0px !important;
    box-shadow: none !important;
}

button.accordion-button:active {
    outline: none !important;
    border: 0px !important;
    box-shadow: none !important;
}

.accordion-item {
    background-color: #fff !important;
    border: 0 !important;
    box-shadow: none !important;
    border: none !important;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    position: relative !important;
    padding-top: 24px !important;
    padding-right: 0px !important;
    padding-bottom: 22px !important;
    cursor: pointer !important;
    padding-left: 0px !important;
    margin: 0 !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #1e1e1e !important;
    line-height: 27px !important;
    letter-spacing: -0.6px !important;
    border: none !important;
}

.card-body {
    padding: 15px 0px !important;
}

.jobs-inner {
    margin-bottom: 90px !important;
}

.accordion-item:last-child {
    border: 0 !important;
}