
.cloud-solutions-wrapper{
    .hero-wrapper{
        // min-height: calc(100vh - 133px);
        background: #EEF3FD;
        background-image: url('../../../../../../public/supply-chain/bg.svg');
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        align-items: center;
        .content{
            text-align: center;
            padding-top: 120px;
            padding-bottom: 160px;
            img{
                width: 50px;
                height: 50px;
                object-fit: contain;
                margin-bottom: 42px;
            }
            p{
                color: #1D68F0;
                margin-bottom: 24px;
            }
            h1{
                color: #111827;
            }
        }   
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .cloud-solutions-wrapper{
        .hero-wrapper{
            .content{
                padding-top: 52px;
                padding-bottom: 96px;
                img{
                    margin-bottom: 42px;
                }
                p{
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 170%;
                    width: 80%;
                    margin: 0 auto;
                    color: #1D68F0;
                    margin-bottom: 24px;
                }
                h1{
                    color: #111827;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 130%; /* 41.6px */
                    letter-spacing: -0.64px;
                    width: 80%;
                    margin: 0 auto;
                }
            } 
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
}