.cloud-solutions.challenges{
    padding-top: 4rem;
    padding-bottom: 96px;
    .right-content{
        padding-top: 1rem;
        p{
            color: #1D68F0;
            font-weight: 600;
        }
        h3{
            font-weight: 600;
        }
    }
    .left-content{
        .item{
            // max-width: 90%;
            width: 100%;
            display: flex;
            align-items: start;
            margin-bottom: 1.2rem;
            svg{
                margin-right: 1rem;
            }
            p{
                font-weight: 400;
            }
        }
    }
    .challenge-image{
        margin: 0 auto;
        width: 100%;
        padding-top: 2rem;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .cloud-solutions.challenges{
        .left-content{
            .item{
                max-width: 100%;
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .cloud-solutions.challenges{
        .left-content{
            .item{
                max-width: 100%;
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .cloud-solutions.challenges{
        .left-content{
            .item{
                max-width: 100%;
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .cloud-solutions.challenges{
        .left-content{
            .item{
                max-width: 90%;
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .cloud-solutions.challenges{
        .left-content{
            .item{
                max-width: 90%;
            }
        }
    }
}