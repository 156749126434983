.cloud-solutions.dynamics{
    padding-top: 4rem;
    padding-bottom: 96px;
    background: #FAFAFC;
    text-align: center;
    .icon{
        margin-bottom: 2rem;
        max-height: 90px;
        max-width: 90px;
    }
    small{
        display: block;
        font-weight: 600;
        text-transform: uppercase;
        color: #1D68F0;
        margin-bottom: 1.5rem;
    }
    h3{
        color: #111827;
        margin-bottom: 2rem;
    }
    p{
        max-width: 850px;
        margin: 0 auto;
        margin-bottom: 4rem;
    }
    .video{
        margin: 0 auto;
        margin-top: 2rem;
        position: relative;
        max-width: 1000px;
        width: 100%;
        max-height: 541px;
        height: 100%;
        border-radius: 14px;
        overflow: hidden;
        .placeholder-image{
            width: 100%;
            position: relative;
            z-index: 90;
        }
        .play-button{
            position: absolute; 
            left: 0;
            right: 0;
            top: 40%;
            bottom: 0;
            margin: 0 auto;
            z-index: 91;
            width: 87px;
            height: 87px;
            object-fit: contain;
            cursor: pointer;
            filter: drop-shadow(5px 15px 12px #00000070);
            transition: all .3s ease;
        }
        .play-button:hover{
            transform: scale(0.95);
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .cloud-solutions.dynamics{
        h3{
            max-width: 80%;
            margin: 0 auto;
            font-family: Inter;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%; /* 41.6px */
            letter-spacing: -0.64px;
        }
        .video{
            border-radius: 0px;
            .play-button{
                position: absolute; 
                left: 0;
                right: 0;
                top: 37%;
                bottom: 0;
                margin: 0 auto;
                z-index: 91;
                width: 65px;
                height: 65px;
                object-fit: contain;
                cursor: pointer;
                filter: drop-shadow(5px 15px 12px #00000070);
                transition: all .3s ease;
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .cloud-solutions.dynamics{
        h3{
            max-width: 80%;
            margin: 0 auto;
            font-family: Inter;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%; /* 41.6px */
            letter-spacing: -0.64px;
        }
        .video{
            border-radius: 0px;
            .play-button{
                position: absolute; 
                left: 0;
                right: 0;
                top: 37%;
                bottom: 0;
                margin: 0 auto;
                z-index: 91;
                width: 65px;
                height: 65px;
                object-fit: contain;
                cursor: pointer;
                filter: drop-shadow(5px 15px 12px #00000070);
                transition: all .3s ease;
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .cloud-solutions.dynamics{
        .video{
            border-radius: 14px;
            padding: 0 2rem;
            .play-button{
                position: absolute; 
                left: 0;
                right: 0;
                top: 40%;
                bottom: 0;
                margin: 0 auto;
                z-index: 91;
                width: 87px;
                height: 87px;
                object-fit: contain;
                cursor: pointer;
                filter: drop-shadow(5px 15px 12px #00000070);
                transition: all .3s ease;
            }
        }
        .challenges{
            .left-content{
                .item{
                    max-width: 100%;
                }
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .cloud-solutions.dynamics{
        .video{
            border-radius: 14px;
            padding: 0 2rem;
            .play-button{
                position: absolute; 
                left: 0;
                right: 0;
                top: 40%;
                bottom: 0;
                margin: 0 auto;
                z-index: 91;
                width: 87px;
                height: 87px;
                object-fit: contain;
                cursor: pointer;
                filter: drop-shadow(5px 15px 12px #00000070);
                transition: all .3s ease;
            }
        }
        .challenges{
            .left-content{
                .item{
                    max-width: 70%;
                }
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .cloud-solutions.dynamics{
        .video{
            border-radius: 14px;
            padding: 0 2rem;
            .play-button{
                position: absolute; 
                left: 0;
                right: 0;
                top: 40%;
                bottom: 0;
                margin: 0 auto;
                z-index: 91;
                width: 87px;
                height: 87px;
                object-fit: contain;
                cursor: pointer;
                filter: drop-shadow(5px 15px 12px #00000070);
                transition: all .3s ease;
            }
        }
    }
}