.business-get-in-touch-wrapper{
    padding-top: 8rem;
    padding-bottom: 48px;
    background-image: url(../../../../public/business/get-in-touch.png);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 25%;
    background-position-y: 100%;
    .left-content{
        // padding-bottom: 3rem;
        // min-height: 500px;
    }
    .right-content{
        padding-bottom: 7rem;
    }
}

.business-succ-stories {
    padding-top: 48px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .business-get-in-touch-wrapper{
        background-size: 100%;
        background-image: none;
        // .left-content{
        //     min-height: 100px;
        // }
        // .right-content{
        //     min-height: 100px;
        //     padding-bottom: 6rem;
        // }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .business-get-in-touch-wrapper{
        background-size: 60%;
        background-image: none;
        // .left-content{
        //     min-height: 100px;
        // }
        // .right-content{
        //     min-height: 100px;
        //     padding-bottom: 6rem;
        // }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .business-get-in-touch-wrapper{
        background-size: 55%;
        background-image: url(../../../../public/business/get-in-touch.png);
        // .left-content{
        //     min-height: 300px;
        // }
        .right-content{
            padding-bottom: 9rem;
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .business-get-in-touch-wrapper{
        background-size: 35%;
        background-image: url(../../../../public/business/get-in-touch.png);
        // .left-content{
        //     min-height: 500px;
        // }
        // .right-content{
        //     min-height: 500px;
        // }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .business-get-in-touch-wrapper{
        background-size: 35%;
        // .left-content{
        //     min-height: 500px;
        // }
        // .right-content{
        //     min-height: 500px;
        // }
    }
}