.careers-why-icons {
    width: 36px;
    height: 36px;
}

.careers-sec-4 {
    background-color: var(--primary-700, #06206E);
    color: #fff !important;
}

.careers-sec-4-content {
    margin-top: 40px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.56px;
}

.careers-sec-4-content-2 {
    margin-top: 24px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.2px;
}

.careers-last-sec-desc {
    color: #fff;
}

#sec-careers-ceipal {
    margin-bottom: 64px;
    .streamline-banner {
        border-radius: 8px;
        padding: 2%;
    }
    .banner {
        padding: 3%;
    }
}

.career-bg-left-img {
    border-radius: 8px;
    opacity: 80%;
}

.career-last-sec {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
}