.header-wrapper{
    // background: #EEEEF2;
    border-bottom: 1px solid #EEEEF2;
    background: #fff;
    .header-links{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .links{
        display: flex;
        .link{
            padding: 1rem 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 3px solid transparent;
            border-radius: 0px;
            cursor: pointer;
            a{
                color: #111827 !important;
            }
        }
        .link.active{
            color: #1d68f0 !important;
            border-bottom: 3px solid #1D68F0
        }
        .link:hover{
            border-bottom: 3px solid #1D68F0
        }
        .link:hover a{
            color: #1d68f0 !important;
        }
        .link:last-child:hover{
            border-bottom: 3px solid transparent;
        }
        .link:last-child a{
            margin: 0;
            color: #fff !important;
        }
        .cloud-link:hover + .cloud-nav-options{
            display: block;
        }
    }
}

.nav-options-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 100;
}

.nav-wrapper{
    width: 100%;
    background: #FAFAFC;
    position: relative;
    .right-white-bg{
        background: #fff;
        position: absolute;
        z-index: 0;
        width: 50%;
        right: 0;
        bottom: 0;
        top: 0;
    }
    .left-side-wrapper{
        border-right: 1px solid #EEEEF2;
    }
    .left-side{
        padding: 3rem 0;
        .heading{
            font-size: 0.7rem;
            font-weight: 600;
            color: #9CA3AF;
            text-transform: uppercase;
        }
        .links{
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            padding-right: 1.2rem;
            .link{
                border-radius: 6px;
                padding: 1rem 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                margin-bottom: 0.3rem;
                p{
                    margin: 0;
                }
                svg{
                    path{
                        fill: #9CA3AF;
                    }
                }
            }
            .link:hover{
                transition: all .3s ease;
                background: #00319C15;
                color: #00319C;
                svg{
                    path{
                        fill: #00319C;
                    }
                }
            }
            .link.active{
                background: #00319C15;
                color: #00319C;
                svg{
                    path{
                        fill: #00319C;
                    }
                }
            }
        }
        .about-wrapper{
            p:last-child{
                color: #6B7280;
                width: 80%;
            }
        }
    }
    .right-bg{
        background: #fff;
    }
    .right-side-wrapper{
        background: #fff;
        .right-side{
            position: relative;
            z-index: 100;
            padding: 3rem 2rem;
            .heading{
                font-size: 0.7rem;
                font-weight: 600;
                color: #9CA3AF;
                padding: 0 1rem;
                text-transform: uppercase;
                background: none;
                margin-top: 0.5rem;
            }
            .service-item{
                display: flex;
                justify-content: start;
                align-items: start;
                margin: 0.5rem 0;
                transition: all .3s ease;
                // height: 100%;
                width: 100%;
                border-radius: 6px;
                cursor: pointer;
                padding: 1rem;
                img{
                    margin-right: 1rem;
                }
                p{
                    color: #9CA3AF;
                    margin: 0;
                    margin-top: -0.4rem;
                }
            }
            .service-item:hover{
                background: #00319C10;
                .content h5{
                    color: #00319C;
                }
            }
            
            .about-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0.5rem 0;
                transition: all .3s ease;
                height: fit-content;
                width: 100%;
                border-radius: 6px;
                cursor: pointer;
                padding: 1rem;
                .content-wrapper{
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    h5{
                        margin: 0;
                    }
                    img{
                        margin-right: 1rem;
                    }
                }
                svg{
                    path{
                        fill: #9CA3AF;
                    }
                }
                p{
                    color: #9CA3AF;
                }
            }
            .about-item:hover{
                background: #00319C10;
                h5{
                    color: #00319C;
                }
                svg{
                    path{
                        fill: #00319C;
                    }
                }
            }
        }
    }
}

.business-nav-wrapper{
    padding: 3rem 0;
    .heading{
        font-size: 0.7rem;
        font-weight: 600;
        color: #9CA3AF;
        padding: 0 1rem;
        text-transform: uppercase;
        background: none;
    }
    .service-item{
        display: flex;
        justify-content: start;
        align-items: start;
        margin: 0.5rem 0;
        transition: all .3s ease;
        width: 100%;
        border-radius: 6px;
        cursor: pointer;
        padding: 1rem;
        img{
            margin-right: 1rem;
        }
        p{
            color: #9CA3AF;
            margin: 0;
            margin-top: -0.4rem;
        }
    }
    .service-item:hover{
        background: #00319C10;
        .content h5{
            color: #00319C;
        }
    }
}

.header-img-sp {
    height: 40px;
    width: 40px;
}